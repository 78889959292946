import React from "react";
import { Link } from "react-router-dom";

function Blog() {
  return (
    <div>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 sm:py-12 md:px-12 md:py-24">
        <div className="flex flex-col lg:flex-row">
          <div
            className="w-full lg:w-1/3 p-10 prose"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <h1 className="text-7xl text-gray-600 leading-tight">
              Bears eat Beets
            </h1>

            <h1 className="text-3xl text-gray-600">BEARS</h1>

            <h1 className="text-3xl text-gray-600">BEATS</h1>

            <h1 className="text-7xl text-gray-600">Battlestar Gallactica </h1>
            <p className="text-2xl text-gray-600 font-light mt-8 leading-relaxed">
              Nullam tincidunt felis eget blandit aliquam. Nunc vel mollis
              lorem. Phasellus pharetra commodo ultricies. Mauris scelerisque
              elit sed arcu consectetur hendrerit et sit amet ligula.
            </p>
            <div className="mt-8 inline-flex gap-x-6 pt-2">
              <Link
                to="/blog"
                href="!#"
                class="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center border border-gray-400 text-gray-900    rounded-lg sm:w-auto hover:bg-gray-100  focus:ring-lime-100 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-4 h-4 mr-2 "
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.72 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L11.69 12 4.72 5.03a.75.75 0 010-1.06zm6 0a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06L17.69 12l-6.97-6.97a.75.75 0 010-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>
                Blog Section
              </Link>
            </div>
          </div>
          <div
            className="w-full lg:w-2/3 py-8 px-10 grid grid-cols-4 gap-4"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <div className="h-64 col-span-4 sm:col-span-4 relative shadow-lg">
              <img
                className="rounded-md"
                src="/Unlocking Business Potential - What is ERP and How It Drives Growth.jpg"
                alt="fresh beets"
                className="absolute inset-0 h-full w-full object-cover"
              />
            </div>
            <div className="h-64 col-span-4 sm:col-span-2 relative shadow-lg">
              <img
                className="rounded-lg"
                src="/What Is Artificial Intelligence and Machine Learning, Are We Using It Correctly.jpg"
                alt="red veggies"
                className="absolute inset-0 h-full w-full object-cover"
              />
            </div>
            <div className="h-64 col-span-4 sm:col-span-2 relative shadow-lg">
              <img
                className="rounded-sm"
                src="https://images.unsplash.com/photo-1595855759920-86582396756a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80"
                alt="asperagas"
                className="absolute inset-0 h-full w-full object-cover"
              />
            </div>
            <div className="h-64 col-span-4 sm:col-span-3 relative shadow-lg">
              <img
                className="rounded-md"
                src="https://images.prismic.io/aarhat/7269023c-fd89-4817-8626-9918fa335f79_5+Best+Programming+Languages+For+Mobile+App+Development+%281%29.png?auto=compress,format"
                alt="carrots"
                className="absolute inset-0 h-full w-full object-cover"
              />
            </div>
            <div className="h-64 col-span-4 sm:col-span-1 relative shadow-lg">
              <img
                className="rounded-md"
                src="https://images.unsplash.com/photo-1558818498-28c1e002b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1287&q=80"
                alt="tomato tomato"
                className="absolute inset-0 h-full w-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
