import React from "react";
import Product from "./Product";
import Verticles from "./Verticles";
import Sister from "./Sister";
import { Link } from "react-router-dom";

function Service() {
  return (
    <div>
      <div className="bg-lime-50">
        <section class="text-gray-600 body-font">
          <div class="container px-5 lg:px-40 py-24 mx-auto">
            <div class="flex flex-wrap w-full mb-20">
              <div class="lg:w-1/2 w-full mb-6 lg:mb-0">
                <h1 class="sm:text-6xl text-2xl font-medium title-font mb-2 text-gray-900">
                  Services{" "}
                  <span className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
                    What
                  </span>
                </h1>
                <div class="h-1 w-20 bg-indigo-500 rounded"></div>
                <span className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
                  {" "}
                  We Provide :
                </span>
              </div>
              <p class="lg:w-1/2 w-full text-base text-justify leading-relaxed text-gray-500">
                In the era of cloud computing, SaaS is at the forefront of
                business efficiency. We specialize in developing cutting-edge
                SaaS applications that help streamline your operations, reduce
                costs, and enhance productivity. Whether you need a custom CRM,
                project management tool, or any other SaaS solution, our team is
                ready to turn your vision into reality.
              </p>
            </div>
            <div class="flex flex-wrap -m-4">
              <div class="xl:w-1/2 md:w-1/2 p-4 card">
                <Link to="/webdev">
                  <div class="shadow-lg hover:shadow-orange-300 shadow-blue-200 hover:shadow-md p-6 rounded-lg">
                    <h3 class="tracking-widest text-gray-900 text-lg font-medium title-font">
                      Web / Mobile
                    </h3>
                    <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                      Development
                    </h2>
                    <p class="leading-relaxed text-base text-justify">
                      Your website is often the first interaction a potential
                      customer has with your brand. Our web development experts
                      are here to ensure that this encounter is nothing short of
                      extraordinary.
                    </p>
                  </div>
                </Link>
              </div>
              <div class="xl:w-1/2 md:w-1/2 p-4 card">
                <Link to="/uiux">
                  <div class="shadow-lg hover:shadow-orange-300 shadow-pink-200 hover:shadow-md p-6 rounded-lg">
                    <h3 class="tracking-widest text-gray-900 text-lg font-medium title-font">
                      UI / UX
                    </h3>
                    <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                      Design
                    </h2>
                    <p class="leading-relaxed text-base text-justify">
                      User experience is at the heart of everything we do. Our
                      UI/UX designers are passionate about creating interfaces
                      that are not only visually appealing but also intuitive
                      and user-centric.
                    </p>
                  </div>
                </Link>
              </div>

              <div class="xl:w-1/2 md:w-1/2 p-4 card">
                <Link to="/cms">
                  <div class="shadow-lg hover:shadow-orange-300 shadow-violet-200 hover:shadow-md p-6 rounded-lg">
                    <h3 class="tracking-widest text-gray-900 text-lg font-medium title-font">
                      Custom
                    </h3>
                    <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                      CMS
                    </h2>
                    <p class="leading-relaxed text-base text-justify">
                      Unlock the power of customization with our Custom CMS
                      Services. Tailored to your unique needs, we craft dynamic
                      and user-friendly CMS for a seamless online presence.
                    </p>
                  </div>
                </Link>
              </div>

              <div class="xl:w-1/2 md:w-1/2 p-4 card">
                <Link to="/erp">
                  <div class="shadow-lg hover:shadow-orange-300 shadow-green-200 hover:shadow-md p-6 rounded-lg">
                    <h3 class="tracking-widest text-gray-900 text-lg font-medium title-font">
                      Custom
                    </h3>
                    <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
                      ERP
                    </h2>
                    <p class="leading-relaxed text-base text-justify">
                      Enhance Efficiency through our Custom ERP Solutions! Our
                      experts design and develop software to streamline business
                      process, enhance productivity, boost your bottom line.
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Product />
      <div className="bg-lime-50">
        <Verticles />
      </div>
      <Sister />
    </div>
  );
}

export default Service;
