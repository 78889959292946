import React from "react";

function UiUx() {
  return (
    <div>
      <section class="text-gray-600 body-font">
        <div class="container mx-auto max-w-screen-xl flex px-5 lg:px-8 py-10 lg:py-24 md:flex-row flex-col items-center">
          <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0  ">
            <h1 class="title-font sm:text-4xl text-3xl mb-6 leading-relaxed py-1 font-medium text-gray-900">
              Our world-class UI/UX
              <br class="inline-block" />
              design that users rave about
            </h1>
            <p class="leading-relaxed text-justify">
              With our UX Methodologies and outstanding Front-End Engineering as
              key differentiators, we are confident of delivering quality
              solutions. Our UI/UX design company in Kolkata strives to create
              outstanding designs for industries such as finance, healthcare,
              and education.
            </p>
          </div>
          <div class="lg:max-w-xl lg:w-full md:w-1/2 w-full">
            <img
              class="object-cover object-center rounded"
              alt="hero"
              src="./UX.png"
            />
          </div>
        </div>
      </section>
      <section>
        <div class="text-center">
          <h2 class="text-3xl font-bold leading-tight text-gray-800 sm:text-4xl xl:text-5xl">
            Our Process
          </h2>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2  container mx-auto max-w-screen-xl gap-8 p-8 py-20">
          <div class="block rounded-xl bg-gradient-to-r from-orange-100  to-transparent opacity-95 shadow p-4 border hover:translate-y-2  duration-700">
            <span class="inline-block rounded-lg p-3">
              <div class="inline-flex align-middle justify-center items-center select-none text-gray-800">
                <svg
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  height="32"
                  width="32"
                >
                  <path
                    d="M8.50141 14.75C8.3114 14.75 8.12141 14.68 7.97141 14.53L5.97141 12.53C5.68141 12.24 5.68141 11.7599 5.97141 11.4699L7.97141 9.46994C8.26141 9.17994 8.7414 9.17994 9.0314 9.46994C9.3214 9.75994 9.3214 10.24 9.0314 10.53L7.5614 12L9.0314 13.4699C9.3214 13.7599 9.3214 14.24 9.0314 14.53C8.8814 14.68 8.69141 14.75 8.50141 14.75Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M16.5014 14.75C16.3114 14.75 16.1214 14.68 15.9714 14.53C15.6814 14.24 15.6814 13.7599 15.9714 13.4699L17.4414 12L15.9714 10.53C15.6814 10.24 15.6814 9.75994 15.9714 9.46994C16.2614 9.17994 16.7414 9.17994 17.0314 9.46994L19.0314 11.4699C19.3214 11.7599 19.3214 12.24 19.0314 12.53L17.0314 14.53C16.8814 14.68 16.6914 14.75 16.5014 14.75Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M12.5 22.75C6.57 22.75 1.75 17.93 1.75 12C1.75 6.07 6.57 1.25 12.5 1.25C18.43 1.25 23.25 6.07 23.25 12C23.25 17.93 18.43 22.75 12.5 22.75ZM12.5 2.75C7.4 2.75 3.25 6.9 3.25 12C3.25 17.1 7.4 21.25 12.5 21.25C17.6 21.25 21.75 17.1 21.75 12C21.75 6.9 17.6 2.75 12.5 2.75Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M11.5021 15.0801C11.4021 15.0801 11.3021 15.0601 11.2121 15.0201C10.8321 14.8601 10.6521 14.4201 10.8221 14.0301L12.8221 9.36004C12.9821 8.98004 13.4221 8.80002 13.8021 8.97002C14.1821 9.13002 14.3621 9.57008 14.1921 9.96008L12.1921 14.6301C12.0721 14.9101 11.7921 15.0801 11.5021 15.0801Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </span>

            <h2 class="mt-2 px-3 font-semibold text-base sm:text-lg text-gray-700">
              User Research :
            </h2>
            <p class="sm:mt-1 px-3 block text-sm sm:text-base text-gray-600 text-justify">
              {" "}
              At the core of our design philosophy depends on a deep
              understanding of your target audience. Our seasoned team of UI/UX
              design experts delves into extensive user research, identifying
              user behaviors, preferences, and pain points. This crucial step
              lays the foundation for a design strategy that resonates with your
              users.
            </p>
          </div>

          <div class="block rounded-xl bg-gradient-to-r from-amber-100  to-transparent opacity-95 shadow p-4 border  hover:translate-y-2  duration-700">
            <span class="inline-block rounded-lg p-3">
              <div class="inline-flex align-middle justify-center items-center select-none text-gray-800">
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  height="32"
                  width="32"
                >
                  <path
                    d="M7.55556 4.22222C7.55556 2.99492 8.55048 2 9.77778 2C11.0051 2 12 2.99492 12 4.22222V5.44444C12 5.99673 12.4477 6.44444 13 6.44444H16.5556C17.1078 6.44444 17.5556 6.89216 17.5556 7.44444V11C17.5556 11.5523 18.0033 12 18.5556 12H19.7778C21.0051 12 22 12.9949 22 14.2222C22 15.4496 21.0051 16.4444 19.7778 16.4444H18.5556C18.0033 16.4444 17.5556 16.8922 17.5556 17.4444V21C17.5556 21.5523 17.1078 22 16.5556 22H13C12.4477 22 12 21.5523 12 21V19.7778C12 18.5504 11.0051 17.5556 9.77778 17.5556C8.55048 17.5556 7.55556 18.5504 7.55556 19.7778V21C7.55556 21.5523 7.10784 22 6.55556 22H3C2.44772 22 2 21.5523 2 21V17.4444C2 16.8922 2.44772 16.4444 3 16.4444H4.22222C5.44952 16.4444 6.44444 15.4496 6.44444 14.2222C6.44444 12.9949 5.44952 12 4.22222 12H3C2.44772 12 2 11.5523 2 11V7.44444C2 6.89216 2.44772 6.44444 3 6.44444H6.55556C7.10784 6.44444 7.55556 5.99673 7.55556 5.44444V4.22222Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  ></path>
                </svg>
              </div>
            </span>

            <h2 class="mt-2 px-3 font-semibold text-base sm:text-lg text-gray-700">
              Work and User Flow :
            </h2>
            <p class="sm:mt-1 px-3 block text-sm sm:text-base text-gray-600 text-justify">
              {" "}
              Once armed with valuable insights, we map out the user journey,
              ensuring a logical and intuitive flow. Our focus is on creating a
              seamless experience, where every click and interaction feels
              natural. This meticulous process ensures that users effortlessly
              navigate through your digital space, driving engagement and
              satisfaction.
            </p>
          </div>

          <div class="block rounded-xl bg-gradient-to-r from-gray-300  to-transparent opacity-95 shadow p-4 border  hover:translate-y-2  duration-700">
            <span class="inline-block rounded-lg p-3">
              <div class="inline-flex align-middle justify-center items-center select-none text-gray-800">
                <svg
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  height="32"
                  width="32"
                >
                  <path
                    d="M12.6586 11.62C12.6286 11.62 12.6086 11.62 12.5786 11.62C12.5286 11.61 12.4586 11.61 12.3986 11.62C9.49859 11.53 7.30859 9.25 7.30859 6.44C7.30859 3.58 9.63859 1.25 12.4986 1.25C15.3586 1.25 17.6886 3.58 17.6886 6.44C17.6786 9.25 15.4786 11.53 12.6886 11.62C12.6786 11.62 12.6686 11.62 12.6586 11.62ZM12.4986 2.75C10.4686 2.75 8.80859 4.41 8.80859 6.44C8.80859 8.44 10.3686 10.05 12.3586 10.12C12.4086 10.11 12.5486 10.11 12.6786 10.12C14.6386 10.03 16.1786 8.42 16.1886 6.44C16.1886 4.41 14.5286 2.75 12.4986 2.75Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M12.6716 22.55C10.7116 22.55 8.74156 22.05 7.25156 21.05C5.86156 20.13 5.10156 18.87 5.10156 17.5C5.10156 16.13 5.86156 14.86 7.25156 13.93C10.2516 11.94 15.1116 11.94 18.0916 13.93C19.4716 14.85 20.2416 16.11 20.2416 17.48C20.2416 18.85 19.4816 20.12 18.0916 21.05C16.5916 22.05 14.6316 22.55 12.6716 22.55ZM8.08156 15.19C7.12156 15.83 6.60156 16.65 6.60156 17.51C6.60156 18.36 7.13156 19.18 8.08156 19.81C10.5716 21.48 14.7716 21.48 17.2616 19.81C18.2216 19.17 18.7416 18.35 18.7416 17.49C18.7416 16.64 18.2116 15.82 17.2616 15.19C14.7716 13.53 10.5716 13.53 8.08156 15.19Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </span>

            <h2 class="mt-2 px-3 font-semibold text-base sm:text-lg text-gray-700">
              Wireframing and Skinning :
            </h2>
            <p class="sm:mt-1 px-3 block text-sm sm:text-base text-gray-600 text-justify">
              {" "}
              With a comprehensive user flow in place, we transition to
              wireframing – the blueprint of your digital solution. Our team
              creates skeletal structures that outline the placement of
              elements, emphasizing functionality. After refining the
              wireframes, we move on to skinning – infusing life into the ui ux
              designs with visually appealing interfaces that align with your
              brand identity.
            </p>
          </div>

          <div class="block rounded-xl bg-gradient-to-r from-teal-200  to-gray-50 opacity-95 shadow p-4 border  hover:translate-y-2  duration-700">
            <span class="inline-block rounded-lg p-3">
              <div class="inline-flex align-middle justify-center items-center select-none text-gray-800">
                <svg
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  height="32"
                  width="32"
                >
                  <path
                    d="M15.2013 6.29998C15.0181 6.48691 14.9155 6.73823 14.9155 6.99998C14.9155 7.26173 15.0181 7.51305 15.2013 7.69998L16.8013 9.29998C16.9882 9.48321 17.2396 9.58584 17.5013 9.58584C17.7631 9.58584 18.0144 9.48321 18.2013 9.29998L21.4602 6.04107C21.7073 5.79397 22.1242 5.87146 22.229 6.20485C22.52 7.13078 22.583 8.11721 22.4078 9.07913C22.1892 10.279 21.61 11.3838 20.7476 12.2463C19.8852 13.1087 18.7804 13.6878 17.5805 13.9064C16.4942 14.1044 15.3767 13.9983 14.3504 13.6034C14.1561 13.5287 13.9339 13.5674 13.7867 13.7146L7.12132 20.38C6.7235 20.7778 6.18393 21.0013 5.62132 21.0013C5.05871 21.0013 4.51914 20.7778 4.12132 20.38C3.7235 19.9822 3.5 19.4426 3.5 18.88C3.5 18.3174 3.7235 17.7778 4.12132 17.38L10.7867 10.7146C10.9339 10.5674 10.9726 10.3452 10.8979 10.1509C10.503 9.12458 10.3969 8.00708 10.5949 6.92083C10.8135 5.72092 11.3926 4.61614 12.255 3.7537C13.1175 2.89127 14.2223 2.31215 15.4222 2.09352C16.3841 1.91826 17.3705 1.98134 18.2965 2.27232C18.6298 2.37708 18.7073 2.79397 18.4602 3.04107L15.2113 6.28998L15.2013 6.29998Z"
                    stroke="currentColor"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </span>

            <h2 class="mt-2 px-3 font-semibold text-base sm:text-lg text-gray-700">
              Prototyping :
            </h2>
            <p class="sm:mt-1 px-3 block text-sm sm:text-base text-gray-600 text-justify">
              {" "}
              To bring your vision to life, we develop interactive prototypes
              that simulate the user experience. This step allows you to test
              and validate the design in a real-world context, enabling us to
              fine-tune and address any potential issues before moving to the
              final development phase.
            </p>
          </div>

          <div class="block rounded-xl bg-gradient-to-r from-pink-200  to-gray-50 opacity-95 shadow p-4 border  hover:translate-y-2  duration-700">
            <span class="inline-block rounded-lg p-3">
              <div class="inline-flex align-middle justify-center items-center select-none text-gray-800">
                <svg
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  height="32"
                  width="32"
                >
                  <path
                    d="M8.50141 14.75C8.3114 14.75 8.12141 14.68 7.97141 14.53L5.97141 12.53C5.68141 12.24 5.68141 11.7599 5.97141 11.4699L7.97141 9.46994C8.26141 9.17994 8.7414 9.17994 9.0314 9.46994C9.3214 9.75994 9.3214 10.24 9.0314 10.53L7.5614 12L9.0314 13.4699C9.3214 13.7599 9.3214 14.24 9.0314 14.53C8.8814 14.68 8.69141 14.75 8.50141 14.75Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M16.5014 14.75C16.3114 14.75 16.1214 14.68 15.9714 14.53C15.6814 14.24 15.6814 13.7599 15.9714 13.4699L17.4414 12L15.9714 10.53C15.6814 10.24 15.6814 9.75994 15.9714 9.46994C16.2614 9.17994 16.7414 9.17994 17.0314 9.46994L19.0314 11.4699C19.3214 11.7599 19.3214 12.24 19.0314 12.53L17.0314 14.53C16.8814 14.68 16.6914 14.75 16.5014 14.75Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M12.5 22.75C6.57 22.75 1.75 17.93 1.75 12C1.75 6.07 6.57 1.25 12.5 1.25C18.43 1.25 23.25 6.07 23.25 12C23.25 17.93 18.43 22.75 12.5 22.75ZM12.5 2.75C7.4 2.75 3.25 6.9 3.25 12C3.25 17.1 7.4 21.25 12.5 21.25C17.6 21.25 21.75 17.1 21.75 12C21.75 6.9 17.6 2.75 12.5 2.75Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M11.5021 15.0801C11.4021 15.0801 11.3021 15.0601 11.2121 15.0201C10.8321 14.8601 10.6521 14.4201 10.8221 14.0301L12.8221 9.36004C12.9821 8.98004 13.4221 8.80002 13.8021 8.97002C14.1821 9.13002 14.3621 9.57008 14.1921 9.96008L12.1921 14.6301C12.0721 14.9101 11.7921 15.0801 11.5021 15.0801Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </span>

            <h2 class="mt-2 px-3 font-semibold text-base sm:text-lg text-gray-700">
              Frontend Development :
            </h2>
            <p class="sm:mt-1 px-3 block text-sm sm:text-base text-gray-600 text-justify">
              {" "}
              Armed with validated prototypes, our skilled developers take
              charge of turning concepts into reality. Utilizing the latest
              technologies and best practices, we ensure that the frontend of
              your digital solution not only meets but exceeds industry
              standards. The result is a visually stunning and highly functional
              user interface that resonates with your target audience.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-gradient-to-b from-fuchsia-200  to-transarent opacity-95">
        <div className="relative isolate overflow-hidden  px-6 py-24 sm:py-32 max-w-screen-xl mx-auto lg:overflow-visible lg:px-0">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    Our Services
                  </h1>
                  <p className="mt-6 text-lg leading-8 text-gray-900 text-justify">
                    With our UX Methodologies and outstanding Front-End
                    Engineering as key differentiators, we are confident of
                    delivering quality solutions. Our UI/UX design company in
                    Kolkata strives to create outstanding designs for industries
                    such as finance, healthcare, and education.
                  </p>
                </div>
              </div>
            </div>
            <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-24 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
              <img
                className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                src="./UX.png"
                alt=""
              />
            </div>
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                  <ul
                    role="list"
                    className="mt-2 space-y-8 text-gray-700 text-justify"
                  >
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          iOS App UI/UX Design :
                        </strong>{" "}
                        At Aarhatiserv, we specialize in creating captivating
                        and user-centric UI/UX designs for iOS applications. Our
                        team of skilled designers understands the intricacies of
                        the Apple ecosystem, ensuring that your app not only
                        meets but exceeds the stringent standards set by iOS.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          Intuitive Navigation :
                        </strong>{" "}
                        We design interfaces that guide users seamlessly through
                        your iOS app, enhancing user engagement and
                        satisfaction.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          Aesthetic Appeal :
                        </strong>{" "}
                        Our designs are not just functional but also visually
                        appealing, creating a memorable user experience that
                        aligns with your brand identity.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          Android App UI/UX Design :
                        </strong>{" "}
                        Crafting compelling UI/UX designs for the diverse
                        Android platform requires a deep understanding of the
                        ecosystem. At Aarhatiserv, our designers are adept at
                        creating experiences that resonate with Android users.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          Device Compatibility :
                        </strong>{" "}
                        We ensure that your app looks and performs seamlessly
                        across a multitude of Android devices, catering to the
                        diverse user base.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          Material Design Principles :
                        </strong>{" "}
                        Our designs adhere to the latest Material Design
                        principles, providing a consistent and delightful
                        experience for your users.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          Hybrid App UI/UX Design :
                        </strong>{" "}
                        As the demand for cross-platform applications rises,
                        Aarhatiserv stands at the forefront of Hybrid App UI/UX
                        design services. We leverage the latest technologies to
                        create seamless and consistent experiences across
                        platforms.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          Code Reusability :
                        </strong>{" "}
                        Our designs optimize code reusability, reducing
                        development time and costs while maintaining a
                        high-quality user experience.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          Responsive Design :
                        </strong>{" "}
                        We ensure that your hybrid app adapts gracefully to
                        various screen sizes and resolutions, providing a
                        cohesive experience on both iOS and Android.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="text-gray-600 body-font">
        <div class="container mx-auto max-w-screen-xl flex px-5 lg:px-8 py-24 md:flex-row flex-col items-center">
          <div class="max-w-screen-xl ">
            <img
              class="object-cover object-center rounded"
              alt="hero"
              src="./Banner 4.jpg"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default UiUx;
