import React from "react";

function Privacy() {
  return (
    <div className="bg-lime-50">
      <div className="2xl:mx-auto  2xl:container py-8 lg:px-44 md:px-6 px-4 ">
        <div className="w-full flex flex-col justify-center items-center">
          <div className=" flex justify-start flex-col items-start w-full text-left space-y-8 ">
            <div className=" flex justify-start items-start flex-col text-left text-justify w-full xl:w-8/12 cursor-pointer  ">
              <h3 className="text-3xl lg:text-4xl  leading-7 md:leading-5 text-left text-gray-900  hover:text-lime-900">
                Privacy Policy :
              </h3>
            </div>
            <div>
              <p className="w-full text-lg text-justify">
                Welcome to Aarhatiserv! At Aarhatiserv, we are committed to
                protecting your privacy and ensuring the security of your
                personal information. This Privacy Policy explains how we
                collect, use, disclose, and safeguard your information when you
                visit our website or use our web development, app development,
                UI/UX design, and SaaS services. By accessing or using our
                services, you consent to the practices described in this Privacy
                Policy.
              </p>
            </div>
            <hr className="border border-gray-300 w-full" />
            <div className="flex justify-start items-start flex-col text-left w-full text-justify cursor-pointer ">
              <h3 className="text-xl font-medium leading-7 md:leading-5 text-left text-gray-900  hover:text-lime-900 ">
                Information That We Gather :
              </h3>

              <ul className="list-disc pl-4 mt-4">
                <li className="text-lg  leading-6 text-gray-900">
                  Personal Information : We may collect personal information,
                  such as your name, email address, phone number, and company
                  name when you voluntarily provide it to us while using our
                  services, filling out contact forms, or communicating with us.
                </li>
                <li className="text-lg mt-4 leading-6 text-gray-900">
                  Usage Information : We automatically collect information about
                  your interaction with our website and services, including your
                  IP address, browser type, operating system, and pages visited.
                  We use cookies and similar technologies to gather this data.
                  Through your browser's settings, you can manage your cookie
                  options.
                </li>
              </ul>
            </div>
            <hr className="border border-gray-300 w-full" />
            <div className="flex justify-start items-start flex-col text-left w-full text-justify cursor-pointer ">
              <h3 className="text-xl font-medium leading-7 md:leading-5 text-left text-gray-900  hover:text-lime-900 ">
                How We Make Use of Your Information :
              </h3>

              <ul className="list-disc pl-4 mt-4">
                <li className="text-lg  leading-6 text-gray-900">
                  Providing Services : We use your personal information to
                  deliver our web development, app development, UI/UX design,
                  and SaaS services, respond to your inquiries, and fulfill your
                  requests.
                </li>
                <li className="text-lg mt-4 leading-6 text-gray-900">
                  Communication : We may use your contact information to send
                  you updates, newsletters, and promotional materials related to
                  our services, but only if you opt-in to receive such
                  communications.
                </li>
                <li className="text-lg mt-4 leading-6 text-gray-900">
                  Improvement : We use usage information to analyze and improve
                  our website and services, tailor content to your interests,
                  and enhance user experience.
                </li>
              </ul>
            </div>

            <hr className="border border-gray-300 w-full" />
            <div className="flex justify-start items-start flex-col text-left w-full text-justify cursor-pointer ">
              <h3 className="text-xl font-medium leading-7 md:leading-5 text-left text-gray-900  hover:text-lime-900 ">
                Information Sharing and Disclosure :
              </h3>

              <ul className="list-disc pl-4 mt-4">
                <li className="text-lg  leading-6 text-gray-900">
                  Third-Party Service Providers : We may share your information
                  with third-party service providers who assist us in operating
                  our website and providing our services. These providers are
                  bound by confidentiality agreements and are not permitted to
                  use your information for any other purpose.
                </li>
                <li className="text-lg mt-4 leading-6 text-gray-900">
                  Legal Compliance : We may disclose your information to comply
                  with legal obligations, respond to lawful requests, or protect
                  our rights, privacy, safety, or property.
                </li>
              </ul>
            </div>
            <hr className="border border-gray-300 w-full" />
            <div className="flex justify-start items-start flex-col text-left w-full text-justify cursor-pointer ">
              <h3 className="text-xl font-medium leading-7 md:leading-5 text-left text-gray-900  hover:text-lime-900 ">
                Your Choices :
              </h3>

              <ul className="list-disc pl-4 mt-4">
                <li className="text-lg  leading-6 text-gray-900">
                  Access and Correction : You have the right to access and
                  correct your personal information held by us. You can do this
                  by contacting us at{" "}
                  <span className="text-blue-600">aarhatiserv@gmail.com</span>
                </li>
                <li className="text-lg mt-4 leading-6 text-gray-900">
                  Opt-Out : You can opt-out of receiving promotional
                  communications from us by following the unsubscribe
                  instructions in the emails we send or by contacting us
                  directly.
                </li>
              </ul>
            </div>
            <hr className="border border-gray-300 w-full" />
            <div className="flex justify-start items-start flex-col text-left w-full text-justify cursor-pointer ">
              <h3 className="text-xl font-medium leading-7 md:leading-5 text-left text-gray-900  hover:text-lime-900 ">
                Security :
              </h3>

              <ul className="list-disc pl-4 mt-4">
                <li className="text-lg  leading-6 text-gray-900">
                  We use industry-standard security measures to prevent
                  unauthorised access, disclosure, modification, or destruction
                  of your information. However, please be aware that no method
                  of transmission over the internet is entirely secure.
                </li>
              </ul>
            </div>
            <hr className="border border-gray-300 w-full" />
            <div className="flex justify-start items-start flex-col text-left w-full text-justify cursor-pointer ">
              <h3 className="text-xl font-medium leading-7 md:leading-5 text-left text-gray-900  hover:text-lime-900 ">
                Children's Privacy :
              </h3>

              <ul className="list-disc pl-4 mt-4">
                <li className="text-lg  leading-6 text-gray-900">
                  Our website and services are not intended for individuals
                  under the age of 18. We do not knowingly collect personal
                  information from children.
                </li>
              </ul>
            </div>
            <hr className="border border-gray-300 w-full" />
            <div className="flex justify-start items-start flex-col text-left w-full text-justify cursor-pointer ">
              <h3 className="text-xl font-medium leading-7 md:leading-5 text-left text-gray-900  hover:text-lime-900 ">
                Changes to this Privacy Policy :
              </h3>

              <ul className="list-disc pl-4 mt-4">
                <li className="text-lg  leading-6 text-gray-900">
                  We may update this Privacy Policy from time to time to reflect
                  changes in our practices and services. We will notify you of
                  any significant changes by posting an updated version on our
                  website.
                </li>
              </ul>
            </div>
            <hr className="border border-gray-300 w-full" />
            <div className="flex justify-start items-start flex-col text-left w-full text-justify cursor-pointer ">
              <h3 className="text-xl font-medium leading-7 md:leading-5 text-left text-gray-900  hover:text-lime-900 ">
                Contact Us :
              </h3>

              <ul className="list-disc pl-4 mt-4">
                <li className="text-lg  leading-6 text-gray-900">
                  If you have any questions, concerns, or requests regarding
                  this Privacy Policy or your personal information, please
                  contact us at{" "}
                  <span className="text-blue-600">079083 82268</span>
                </li>
                <p className="w-full py-4 text-lg text-justify">
                  Thank you for choosing Aarhatiserv!
                </p>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
