import React from "react";

function Erp() {
  return (
    <div>
      <section class="text-gray-600 body-font">
        <div class="container mx-auto max-w-screen-xl flex px-5 lg:px-8 py-10 lg:py-24 md:flex-row flex-col items-center">
          <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0  ">
            <h1 class="title-font sm:text-4xl text-3xl mb-6 leading-relaxed py-1 font-medium text-gray-900">
              Custom ERP Software
              <br class="inline-block" />
              Development Services
            </h1>
            <p class="leading-relaxed text-justify">
              Connecting people and processes together through dynamic and
              scalable ERP software development solutions. From startups to
              enterprises, we assist organisations to achieve more with our
              end-to-end ERP software development services.
              <br />
              <br />
              We simplify complicated business operations with our ERP software
              development solutions that improve overall efficiency and
              productivity. As a well-established ERP application development
              company we cater to every industry, system, and business size,
              allowing them to navigate through numerous business values and
              networks such as payroll, accounting, inventory, supply chain,
              CRM, and more.
            </p>
          </div>
          <div class="lg:max-w-xl lg:w-full md:w-1/2 w-full">
            <img
              class="object-cover object-center rounded"
              alt="hero"
              src="https://images.pexels.com/photos/3861967/pexels-photo-3861967.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            />
          </div>
        </div>
      </section>
      {/* our services section start  */}
      <section className="max-w-screen-xl container mx-auto">
        <h1 className="text-xl lg:text-3xl font-bold py-6 px-8 text-justify">
          Our integrated ERP software development services empower enterprises
          to optimize various facets of their operations seamlessly.
        </h1>
        <h2 className=" text-lg lg:text-2xl font-semibold py-6 text-justify px-8">
          Let’s delve into the comprehensive modules that define the prowess of
          Aarhatiserv’s ERP software:
        </h2>
        <div class="bg-white">
          <section class="mx-auto w-full gap-y-4   px-7 py-10 sm:px-8 flex flex-col">
            <h2 class="w-full text-base font-bold text-gray-700 sm:text-xl text-justify ">
              Inventory and Warehouse Management :{" "}
              <span className="text-gray-600 font-normal text-base  text-justify">
                Efficient inventory and warehouse management are the cornerstone
                of a successful business. Aarhatiserv’s ERP solution provides
                real-time visibility into inventory levels, enabling businesses
                to track stock movements, manage warehouses effectively, and
                optimize inventory levels to prevent stockouts or overstock
                situations. With features like automated replenishment, barcode
                scanning, and demand forecasting, businesses can streamline
                operations and enhance overall efficiency
              </span>
            </h2>
          </section>
        </div>

        <div class="bg-lime-50">
          <section class="mx-auto w-full gap-y-4   px-7 py-10 sm:px-8 flex flex-col">
            <h2 class="w-full text-base font-bold text-gray-700 sm:text-xl text-justify ">
              Human Resource Management :{" "}
              <span className="text-gray-600 font-normal text-base  text-justify">
                Managing a workforce efficiently is simplified through
                Aarhatiserv’s HR management module. From employee onboarding to
                performance evaluation, our solution automates HR processes,
                including payroll management, attendance tracking, leave
                management, and talent acquisition. This module fosters a
                collaborative work environment while ensuring compliance with
                regulatory standards.
              </span>
            </h2>
          </section>
        </div>

        <div class="bg-white">
          <section class="mx-auto w-full gap-y-4   px-7 py-10 sm:px-8 flex flex-col">
            <h2 class="w-full text-base font-bold text-gray-700 sm:text-xl text-justify ">
              Customer Relationship Management (CRM) :{" "}
              <span className="text-gray-600 font-normal text-base  text-justify">
                The core of any successful business depends on its relationship
                with customers. Aarhatiserv’s CRM module equips businesses with
                tools to nurture and manage customer interactions effectively.
                With features like lead management, contact tracking, sales
                pipeline visualization, and customer support integration, our
                CRM module empowers businesses to forge lasting and profitable
                relationships with their clientele.
              </span>
            </h2>
          </section>
        </div>

        <div class="bg-lime-50">
          <section class="mx-auto w-full gap-y-4   px-7 py-10 sm:px-8 flex flex-col">
            <h2 class="w-full text-base font-bold text-gray-700 sm:text-xl text-justify ">
              Purchase Management :{" "}
              <span className="text-gray-600 font-normal text-base  text-justify">
                Simplify and streamline procurement processes with Aarhatiserv’s
                purchase management module. From vendor management to purchase
                order generation and approval workflows, this module ensures
                transparency and efficiency in procurement activities. It
                enables businesses to make informed decisions, negotiate better
                deals, and optimize their procurement cycle.
              </span>
            </h2>
          </section>
        </div>

        <div class="bg-white">
          <section class="mx-auto w-full gap-y-4   px-7 py-10 sm:px-8 flex flex-col">
            <h2 class="w-full text-base font-bold text-gray-700 sm:text-xl text-justify ">
              Accounting and Finance Management :{" "}
              <span className="text-gray-600 font-normal text-base text-justify">
                Aarhatiserv’s ERP software development services include a robust
                accounting and finance management module that provides
                comprehensive financial insights and controls. From general
                ledger management to accounts payable/receivable, budgeting, and
                financial reporting, this module offers real-time visibility
                into financial data, ensuring accuracy and compliance while
                facilitating informed decision-making.
              </span>
            </h2>
          </section>
        </div>
      </section>
      {/* why choose us section  */}

      <section id="new-features" class="py-8 bg-white sm:py-10 lg:py-24">
        <div class="px-4 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
          <div class="text-center">
            <h2 class="text-3xl font-bold leading-tight text-gray-900 sm:text-4xl xl:text-5xl">
              WHY CHOOSE US ?
            </h2>
          </div>
          <div class="grid grid-cols-1 mt-10 text-center sm:mt-16 sm:grid-cols-2 border sm:gap-x-12 gap-y-12 md:grid-cols-2 md:gap-0 xl:mt-24">
            <div class="p-6 md:p-8 lg:p-6 flex flex-col bg-gray-50 justify-center items-center hover:translate-y-2 hover:bg-gray-100 duration-700">
              <div class="w-14 h-14 rounded-full bg-purple-200 flex justify-center items-center">
                <i class="fa-solid fa-chart-column text-3xl text-gray-900"></i>
              </div>
              <h3 class="mt-12 text-xl font-bold text-gray-900">
                Tailored Solutions, Unparalleled Expertise :
              </h3>
              <p class="mt-5 text-base text-gray-600 text-justify leading-relaxed">
                {" "}
                Aarhatiserv's hallmark depends on its ability to understand
                unique business requirements and craft bespoke custom ERP
                development solutions. With a team of seasoned developers and
                consultants well-versed in diverse industries, they ensure a
                precise fit for your business processes. Our expertise spans
                across modules like finance, HR, inventory, and more.
              </p>
            </div>

            <div class="p-6 md:p-8 lg:p-6 border-l border-gray-200 flex flex-col justify-center items-center hover:translate-y-2 hover:bg-sky-100 duration-700">
              <div class="w-14 h-14 rounded-full bg-teal-200 flex justify-center items-center">
                <i class="fa-solid fa-truck-fast text-3xl text-gray-900"></i>
              </div>
              <h3 class="mt-12 text-xl font-bold text-gray-900">
                Innovation at the Core :
              </h3>
              <p class="mt-5 text-base text-gray-600 text-justify leading-relaxed">
                Striving for innovation, Aarhatiserv consistently pushes
                boundaries to integrate cutting-edge technologies within our ERP
                software development services. From AI-driven analytics to
                IoT-enabled functionalities, our solutions are designed to
                future-proof your business operations, empowering you to stay
                ahead in a rapidly evolving market.
              </p>
            </div>

            <div class="p-6 md:p-8 lg:p-6  border-t border-gray-200 flex flex-col justify-center items-center hover:translate-y-2 hover:bg-yellow-100 duration-700">
              <div class="w-14 h-14 rounded-full bg-yellow-200 flex justify-center items-center">
                <i class="fa-solid fa-shield text-3xl text-gray-900"></i>
              </div>
              <h3 class="mt-12 text-xl font-bold text-gray-900">
                Seamless Integration and Scalability :
              </h3>
              <p class="mt-5 text-base text-gray-600 text-justify leading-relaxed">
                We understand the importance of a seamless transition during ERP
                implementation. Our solutions are designed for effortless
                integration with existing systems, minimizing disruption and
                maximizing efficiency. Moreover, our scalable designs ensure
                that the ERP system grows with your business.
              </p>
            </div>

            <div class="p-6 md:p-8 lg:p-6 border-t border-l bg-gray-50 border-gray-200 flex flex-col justify-center items-center hover:translate-y-2 hover:bg-pink-100 duration-700">
              <div class="w-14 h-14 rounded-full bg-red-200 flex justify-center items-center">
                <i class="fa-solid fa-cloud text-3xl text-gray-900"></i>
              </div>
              <h3 class="mt-12 text-xl font-bold text-gray-900">
                Robust Support and Maintenance :
              </h3>
              <p class="mt-5 text-base text-gray-600 text-justify leading-relaxed">
                Aarhatiserv's success stories speak volumes. With a robust
                portfolio showcasing successful custom ERP development solution
                implementations across various industries, we've garnered a
                reputation for delivering results that exceed expectations,
                earning the trust of clients worldwide.
              </p>
            </div>

            <div class="p-6 md:p-8 lg:p-6  border-gray-200 bg-gray-50 border-t flex flex-col justify-center items-center hover:translate-y-2 hover:bg-teal-100 duration-700">
              <div class="w-14 h-14 rounded-full bg-green-200 flex justify-center items-center">
                <i class="fa-solid fa-pen-nib text-3xl text-gray-900"></i>
              </div>
              <h3 class="mt-12 text-xl font-bold text-gray-900">
                Client-Centric Approach :
              </h3>
              <p class="mt-5 text-base text-gray-600 text-justify leading-relaxed">
                OrgAt the heart of Aarhatiserv's ethos is dedication to client
                satisfaction. We prioritize open communication, fostering
                collaborative partnerships that prioritize your goals and
                aspirations. Our agile methodology ensures transparency.
              </p>
            </div>

            <div class="p-6 md:p-8 lg:p-6 border-l border-gray-200 border-t flex flex-col justify-center items-center hover:translate-y-2 hover:bg-orange-100 duration-700">
              <div class="w-14 h-14 rounded-full bg-orange-200 flex justify-center items-center">
                <i class="fa-solid fa-bolt text-3xl text-gray-900"></i>
              </div>
              <h3 class="mt-12 text-xl font-bold text-gray-900">
                Proven Track Record :
              </h3>
              <p class="mt-5 text-base text-gray-600 text-justify leading-relaxed">
                Aarhatiserv's success stories speak volumes. With a robust
                portfolio showcasing successful custom ERP development solution
                implementations across various industries, we've garnered a
                reputation for delivering results that exceed expectations,
                earning the trust of clients worldwide.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="text-gray-600 body-font">
        <div class="container mx-auto max-w-screen-xl flex px-5 lg:px-8 py-24 md:flex-row flex-col items-center">
          <div class="max-w-screen-xl ">
            <img
              class="object-cover object-center rounded"
              alt="hero"
              src="./Banner 3.jpg"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Erp;
