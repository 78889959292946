import React, { useState } from "react";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const [status, setStatus] = useState("Send Data");
  const [mailSent, setMailSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    console.log("Sender:" + e.target.elements.email.value);
    let details = {
      name: e.target.elements.name.value,
      email: e.target.elements.email.value,
      description: e.target.elements.description.value,
      type: e.target.elements.type.value,
      state: e.target.elements.state.value,
      city: e.target.elements.city.value,
      mobile: e.target.elements.mobile.value,
    };
    console.log(details);
    let response = await fetch(
      "https://aarhatmain.onrender.com/api/contact/contact",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details),
      }
    );
    setStatus("Submit");
    let result = await response.json();
    console.log(result);
    if (result.status === "Message Sent") {
      toast.success("you will be notified soon !", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        transition: Slide,
        theme: "dark",
      });
      setMailSent(true);
    } else {
      toast.error("Message failed to send.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        transition: Slide,
        theme: "dark",
      });
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="bg-lime-50">
        <div className="2xl:mx-auto 2xl:container  py-12  lg:px-40 md:px-6 px-4 ">
          <div className="w-full flex flex-col justify-center items-center">
            <div className=" flex justify-start flex-col items-start w-full text-left space-y-8">
              <div className=" flex justify-start items-start flex-col text-left text-justify w-full xl:w-8/12 cursor-pointer  ">
                <h3 className="text-3xl px-2.5 lg:text-4xl  leading-7 md:leading-5 text-left text-gray-900  hover:text-lime-900">
                  Write to me -
                </h3>
              </div>

              <form
                class="py-4 flex flex-wrap flex-row "
                id="contact"
                action=""
                encType="multipart/form-data"
                method="post"
                onSubmit={handleSubmit}
              >
                <div class="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                  <label
                    htmlFor="name"
                    class="inline-block mb-4 text-xl  text-gray-900"
                  >
                    Full Name :
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    class="w-full leading-5 relative py-3 px-5 text-gray-900  border border-gray-400 overflow-x-auto focus:outline-none "
                    placeholder="Enter Your Name"
                    required
                    autoFocus
                  />
                </div>

                <div class="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                  <label
                    htmlFor="email"
                    class="inline-block mb-4 text-xl text-gray-900"
                  >
                    Email :
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    class="w-full leading-5 relative py-3 px-5 text-gray-900 border border-gray-400 overflow-x-auto focus:outline-none "
                    placeholder="Enter Your Email"
                    required
                    autoFocus
                  />
                </div>

                <div class="flex-shrink max-w-full px-4 mt-4 w-full md:w-1/2 mb-6">
                  <label
                    htmlFor="mobile"
                    class="inline-block mb-4 text-xl  text-gray-900"
                  >
                    Mobile Number :
                  </label>
                  <input
                    type="text"
                    id="mobile"
                    name="mobile"
                    class="w-full leading-5 relative py-3 px-5 text-gray-900  border border-gray-400 overflow-x-auto focus:outline-none "
                    placeholder="Enter Your Mobile"
                    required
                    autoFocus
                  />
                </div>

                <div class="flex-shrink max-w-full px-4 w-full mt-4  md:w-1/2 mb-6">
                  <label
                    htmlFor="type"
                    class="inline-block mb-4 text-xl text-gray-900"
                  >
                    Types Of Business :
                  </label>
                  <input
                    id="type"
                    name="type"
                    class="w-full leading-5 relative py-3 px-5 text-gray-900 border border-gray-400 overflow-x-auto focus:outline-none "
                    placeholder="Enter Your Business Type"
                    required
                    autoFocus
                  />
                </div>

                <div class="flex-shrink max-w-full px-4 mt-4 w-full md:w-1/2 mb-6">
                  <div className="flex flex-col">
                    <label
                      htmlFor="state"
                      class="inline-block mb-4 text-xl  text-gray-900"
                    >
                      State :
                    </label>
                    <select
                      class="w-full leading-5 relative py-3 px-5 text-gray-900  border border-gray-400 overflow-x-auto focus:outline-none "
                      name="state"
                      id="state"
                    >
                      <option>Click to choose State</option>
                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                      <option value="Andaman and Nicobar Islands">
                        Andaman and Nicobar Islands
                      </option>
                      <option value="Arunachal Pradesh">
                        Arunachal Pradesh
                      </option>
                      <option value="Assam">Assam</option>
                      <option value="Bihar">Bihar</option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Chhattisgarh">Chhattisgarh</option>
                      <option value="Dadar and Nagar Haveli">
                        Dadar and Nagar Haveli
                      </option>
                      <option value="Daman and Diu">Daman and Diu</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Lakshadweep">Lakshadweep</option>
                      <option value="Puducherry">Puducherry</option>
                      <option value="Goa">Goa</option>
                      <option value="Gujarat">Gujarat</option>
                      <option value="Haryana">Haryana</option>
                      <option value="Himachal Pradesh">Himachal Pradesh</option>
                      <option value="Jammu and Kashmir">
                        Jammu and Kashmir
                      </option>
                      <option value="Jharkhand">Jharkhand</option>
                      <option value="Karnataka">Karnataka</option>
                      <option value="Kerala">Kerala</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Manipur">Manipur</option>
                      <option value="Meghalaya">Meghalaya</option>
                      <option value="Mizoram">Mizoram</option>
                      <option value="Nagaland">Nagaland</option>
                      <option value="Odisha">Odisha</option>
                      <option value="Punjab">Punjab</option>
                      <option value="Rajasthan">Rajasthan</option>
                      <option value="Sikkim">Sikkim</option>
                      <option value="Tamil Nadu">Tamil Nadu</option>
                      <option value="Telangana">Telangana</option>
                      <option value="Tripura">Tripura</option>
                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                      <option value="Uttarakhand">Uttarakhand</option>
                      <option value="West Bengal">West Bengal</option>
                    </select>{" "}
                  </div>
                </div>

                <div class="flex-shrink max-w-full px-4 w-full mt-4  md:w-1/2 mb-6">
                  <label
                    htmlFor="city"
                    class="inline-block mb-4 text-xl text-gray-900"
                  >
                    City :
                  </label>
                  <input
                    id="city"
                    name="city"
                    type="text"
                    class="w-full leading-5 relative py-3 px-5 text-gray-900 border border-gray-400 overflow-x-auto focus:outline-none "
                    placeholder="Enter Your Business Type"
                    required
                    autoFocus
                  />
                </div>

                <div class="flex-shrink mt-5 max-w-full px-4 w-full mb-6">
                  <label
                    htmlFor="description"
                    class="inline-block mb-4 text-xl text-gray-900"
                  >
                    Business Details :
                  </label>
                  <textarea
                    class="w-full leading-5 relative py-3 px-5 text-gray-900 border border-gray-400 overflow-x-auto focus:outline-none "
                    name="description"
                    id="description"
                    placeholder="Write your queries ..."
                    rows="5"
                  ></textarea>
                </div>
                <div class="flex-shrink max-w-full px-4 w-full mb-6">
                  <label class="flex items-center">
                    <input
                      type="checkbox"
                      name="checked-demo"
                      value="1"
                      class="form-checkbox h-5 w-5 text-blue-500  border border-gray-100 dark:border-gray-700 focus:outline-none"
                      required
                    />
                    <span class="text-gray-800 ml-4">
                      Given information in correct
                    </span>
                  </label>
                </div>
                <div class="flex-shrink max-w-full px-4 w-full">
                  <button
                    class="font-medium tracking-wide bg-lime-300 py-3 px-6 rounded-lg shadow-lg text-gray-900 transition-colors duration-200 hover:text-deep-purple-accent-400 "
                    type="submit"
                  >
                    {status}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
