import React from "react";
import Teams from "./Teams";

function About() {
  return (
    <div className="">
      <div class="2xl:container 2xl:mx-auto lg:py-16 lg:px-40 md:py-12 md:px-6 py-9 px-4">
        <div class="flex flex-col lg:flex-row justify-between gap-8">
          <div class="w-full lg:w-5/12 flex flex-col justify-center">
            <h1 class="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">
              About Us
            </h1>
            <p class="font-normal text-lg text-justify leading-relaxed text-gray-600 ">
              Welcome to Aarhatiserv, your trusted partner in the world of
              Software as a Service (SaaS), web development, app development,
              and UI/UX design. We are a passionate team of tech enthusiasts
              dedicated to crafting digital solutions that empower businesses
              and individuals alike. Our journey is rooted in innovation,
              excellence, and a commitment to delivering results that exceed
              expectations.
            </p>
          </div>
          <div class="w-full lg:w-7/12">
            <img
              class="w-full h-full"
              src="/About us 1.png"
              alt="A group of People"
            />
          </div>
        </div>

        <div class="flex lg:flex-row flex-col  justify-between gap-8 pt-12">
          <div class="w-full  flex flex-col justify-center">
            <h1 class="text-3xl lg:text-4xl font-bold leading-9 text-gray-800  pb-4">
              Our Story
            </h1>
            <p class="font-normal text-lg text-justify leading-relaxed text-gray-600 ">
              In this dynamic and ever-changing digital era, Aarhatiserv stands
              as a beacon of innovation and expertise. Recognizing the perpetual
              evolution of the digital landscape, we prioritize staying at the
              forefront of industry trends. Comprising a team of seasoned
              professionals, we not only bring expertise in our respective
              domains but also embrace a culture of perpetual learning and
              refinement. Our commitment to continuous improvement aligns with
              our belief in the transformative potential of technology. At
              Aarhatiserv, we don't just solve problems; we architect solutions
              that anticipate future challenges. By fostering an environment
              where creativity meets cutting-edge technology, we pave the way
              for seamless user experiences that redefine industry standards.
              Trust us to navigate the complexities of the digital realm, as we
              remain dedicated to pushing boundaries and leveraging technology
              to empower your success.
            </p>
          </div>
        </div>
      </div>
      <Teams />

      <div class="bg-white ">
        <div class="mx-auto container max-w-screen-2xl lg:py-28 lg:px-40 md:py-12 md:px-6 py-9 px-4">
          <div class="mb-6 flex justify-center gap-4">
            <h2 class="text-5xl  text-center font-semibold text-gray-800 lg:text-5xl">
              Certifications
            </h2>
          </div>
          <div class="mb-6 flex px-4 justify-center gap-4">
            <h2 class="text-2xl  text-center font-bold text-gray-800 lg:text-2xl">
              Member Of :
            </h2>
          </div>

          <div class="py-24 grid gap-x-4 gap-y-6 sm:grid-cols-2 md:gap-x-16 lg:grid-cols-3 xl:grid-cols-3">
            <div>
              <div class="group mb-2 lg:mt-16 block lg:mb-3">
                <img
                  src="/logo.png"
                  loading="lazy"
                  alt=""
                  class=" object-center transition duration-200 group-hover:scale-110"
                />
              </div>

              <div class="flex flex-col justify-center">
                <span class="text-gray-500 text-center"> Startup Bengal</span>
              </div>
            </div>

            <div>
              <div class="group mb-2 block  lg:mb-3">
                <img
                  src="/startup-india-4.jpg"
                  loading="lazy"
                  alt=""
                  class="object-center transition duration-200 group-hover:scale-110"
                />
              </div>

              <div class="flex flex-col justify-center">
                <span class="text-gray-500 text-center">Startup India</span>
              </div>
            </div>

            <div>
              <div class="group mb-2 block  lg:mb-3">
                <img
                  src="/msme-1-removebg-preview.png"
                  loading="lazy"
                  alt=""
                  class=" object-center transition duration-200 group-hover:scale-110"
                />
              </div>

              <div class="flex flex-col justify-center">
                <span class="text-gray-500 text-center">
                  {" "}
                  Ministry of MSME, Govt. of India
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
