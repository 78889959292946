import React from "react";

function Testimonial() {
  return (
    <section class="bg-white dark:bg-gray-900">
      <div class="container px-6 py-20 mx-auto">
        <h1 class="text-2xl font-semibold text-center text-gray-800 capitalize lg:text-3xl dark:text-white">
          What our <span class="text-lime-300 ">clients</span> say
        </h1>

        <p class="max-w-2xl mx-auto mt-6 text-center text-gray-500 dark:text-gray-300">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo incidunt
          ex placeat modi magni quia error alias, adipisci rem similique, at
          omnis eligendi optio eos harum.
        </p>

        <div class="grid grid-cols-1 gap-8 mx-auto mt-8 lg:grid-cols-2 xl:mt-10 max-w-7xl">
          <div class="p-6 bg-gray-100 rounded-lg dark:bg-gray-800 md:p-8">
            <p class="leading-loose text-justify text-gray-500 dark:text-gray-300">
              “We've had an impressive experience with their IT Consulting
              services! Their team's remarkable responsiveness and strategic
              guidance have been invaluable in helping us navigate complex tech
              challenges with ease.”
            </p>

            <div class="flex items-center mt-6">
              <img
                class="object-cover rounded-full w-14 h-14"
                src="https://cdn.pixabay.com/photo/2017/11/10/05/48/user-2935527_1280.png"
                alt=""
              />

              <div class="mx-4">
                <h1 class="font-semibold text-lime-300">Chayan Das Gupta</h1>
                <span class="text-sm text-gray-500 dark:text-gray-300">
                  CTO, Robert Consultency
                </span>
              </div>
            </div>
          </div>

          <div class="p-6 bg-gray-100 rounded-lg dark:bg-gray-800 md:p-8">
            <p class="leading-loose text-justify text-gray-500 dark:text-gray-300">
              “We could be happier with this 0utstanding IT Consulting Company!
              Their expertise and innovative solutions have completely
              transformed our business operations, boosting efficiency and
              security to new heights.”
            </p>

            <div class="flex items-center mt-6">
              <img
                class="object-cover rounded-full w-14 h-14"
                src="https://cdn.pixabay.com/photo/2017/11/10/05/48/user-2935527_1280.png"
                alt=""
              />

              <div class="mx-4">
                <h1 class="font-semibold text-lime-300">Priyanka Maity</h1>
                <span class="text-sm text-gray-500 dark:text-gray-300">
                  Marketing Manager at Stech
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonial;
