import React from "react";
import { Link } from "react-router-dom";
import { usePrismicDocumentsByType, PrismicRichText } from "@prismicio/react";

function Product() {
  const [articles] = usePrismicDocumentsByType("article");
  return (
    <div className="bg-gray-50">
      <div class="2xl:mx-auto 2xl:container 2xl:px-36 xl:px-12 sm:px-6 px-4 py-16">
        <h1 class="sm:text-3xl lg:text-5xl text-2xl font-medium title-font leading-9 text-gray-900 ">
          Some Of Our Products :
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 mt-12 gap-6">
          {articles?.results?.map((article, index) => (
            <>
              <div key={article.id}>
                <div className="relative">
                  <img
                    src={article?.data.banner.url}
                    className="w-full h-[400px] object-cover shadow-lg shadow-lime-200"
                    alt={article?.data.banner.alt}
                  />
                </div>

                <h1 className=" text-3xl font-semibold leading-7 font-assistant tracking-wide text-black sm:pr-20 mt-6">
                  <PrismicRichText field={article.data.heading} />
                </h1>
                <p className=" text-base leading-normal text-justify mt-4 sm:pr-20 md:pr-10 font-assistant tracking-wide text-neutral-900 line-clamp-3">
                  <PrismicRichText field={article.data.description} />
                </p>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Product;
