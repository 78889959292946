import React from "react";

function ContentMs() {
  return (
    <div>
      <div class="bg-white py-6 sm:py-8 lg:py-24">
        <div class="mx-auto max-w-screen-xl px-4 md:px-8">
          <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
            <div class="md:pt-8 lg:flex lg:flex-col lg:justify-center">
              <p class="text-center mb-2 font-semibold text-green-500 md:text-left">
                Custom CMS Development Services
              </p>

              <h1 class="mb-4 text-center text-2xl font-semibold text-gray-800 sm:text-3xl md:mb-6 md:text-left">
                CMS Development Company in India
              </h1>

              <p class="mb-6 text-gray-500 sm:text-base md:mb-8 text-justify">
                Aarhatiserv's Custom CMS Solutions are SEO-friendly and
                results-driven, built to work seamlessly with your existing
                content management systems to improve and elevate your website,
                application, social media network, e-commerce store, and blogs.
              </p>
            </div>
            <div>
              <div class="h-64 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-auto">
                <img
                  src="./CUSTOM CMS.png"
                  loading="lazy"
                  alt=""
                  class="h-full w-full object-cover object-center"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="bg-emerald-900">
        <div class="mx-auto grid max-w-lg gap-x-8 gap-y-12 px-4 py-32 md:max-w-screen-xl md:grid-cols-2 md:px-8 lg:grid-cols-3">
          <div>
            <h2 class="text-3xl font-medium text-white">
              Get Custom CMS <br />
              Development Services to
              <br />
              Improve Your Business <br />
              Outlook as well.
            </h2>
          </div>

          <div class="border-white hover:border-amber-200 hover:translate-y-2 duration-700 text-white hover:text-amber-300 relative border-4 px-4 pt-14 pb-8">
            <div class="absolute -top-8 left-8 bg-emerald-900 px-4 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-16 w-16"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z"
                />
              </svg>
            </div>
            <p class="mb-3 font-medium  text-lg uppercase ">
              CMS Development Services
            </p>
            <p class=" text-justify">
              Our CMS Development Services are crafted to provide you with a
              powerful and user-friendly content management solution. We
              understand that each business has distinct requirements, and our
              expert developers ensure a tailor-made CMS solutions that aligns
              seamlessly with your goals. From intuitive user interfaces to
              efficient workflow management, we focus on enhancing your content
              creation and management experience.
            </p>
          </div>
          <div class="border-white hover:border-amber-200 hover:translate-y-2 duration-700 text-white hover:text-amber-300 relative border-4 px-4 pt-14 pb-8">
            <div class="absolute -top-8 left-8 bg-emerald-900 px-4 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-16 w-16"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z"
                />
              </svg>
            </div>
            <p class="mb-3 font-medium text-lg uppercase ">CMS Integrations</p>
            <p class=" text-justify">
              Integrating disparate systems seamlessly is crucial for a unified
              online presence. Our CMS Integration services facilitate the
              incorporation of third-party applications, APIs, and plugins to
              ensure a cohesive and efficient digital ecosystem. Whether it's
              e-commerce integrations, CRM systems, or other essential tools, we
              streamline your workflow for optimal performance.
            </p>
          </div>
          <div class="border-white hover:border-amber-200 hover:translate-y-2 duration-700 text-white hover:text-amber-300 relative border-4 px-4 pt-14 pb-8">
            <div class="absolute -top-8 left-8 bg-emerald-900 px-4 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-16 w-16"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z"
                />
              </svg>
            </div>
            <p class="mb-3 font-medium uppercase text-lg ">
              CMS Application Development
            </p>
            <p class=" text-justify">
              For businesses with unique functionalities and specific
              requirements, our CMS Application Development services are the
              answer. Our skilled developers leverage cutting-edge technologies
              to build custom applications that complement your CMS, enhancing
              its capabilities and providing a tailored digital solution.
            </p>
          </div>
          <div class="border-white hover:border-amber-200 hover:translate-y-2 text-white hover:text-amber-300 duration-700 relative border-4 px-4 pt-14 pb-8">
            <div class="absolute -top-8 left-8 bg-emerald-900 px-4 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-16 w-16"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z"
                />
              </svg>
            </div>
            <p class="mb-3 font-medium text-lg uppercase ">
              CMS Plugin Development
            </p>
            <p class=" text-justify">
              Enhance the functionality of your CMS with our bespoke Plugin
              Development services. Whether you require custom features,
              extensions, or widgets, our developers create plugins that
              seamlessly integrate with your CMS, providing a personalized and
              efficient content management experience.
            </p>
          </div>
          <div class="border-white hover:border-amber-200 hover:translate-y-2 text-white hover:text-amber-300 duration-700 relative border-4 px-4 pt-14 pb-8">
            <div class="absolute -top-8 left-8 bg-emerald-900 px-4 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-16 w-16"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z"
                />
              </svg>
            </div>
            <p class="mb-3 font-medium text-lg uppercase ">CMS Migration</p>
            <p class=" text-justify">
              In a dynamic digital environment, CMS platforms evolve, and
              businesses may need to migrate for improved features and security.
              Our CMS Migration services ensure a smooth transition, preserving
              your existing data, and seamlessly adapting your content to the
              new platform. We prioritize minimal downtime and optimal
              performance during the migration process.
            </p>
          </div>
        </div>
      </section>

      <section class="px-4 lg:px-8 py-24 mx-auto max-w-screen-xl">
        <div class="grid items-center grid-cols-1 mb-24 md:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
          <div>
            <h2 class="mb-4 text-2xl font-bold tracking-tight text-center text-emerald-600 md:leading-tight sm:text-left md:text-4xl">
              Custom CMS Solutions :
            </h2>
            <p class="mb-4 text-base  text-gray-600  md:text-base text-justify">
              Embrace the freedom of customization with our tailor-made CMS
              solutions. Our team of experienced developers works closely with
              clients to understand their specific requirements, ensuring that
              the resulting CMS aligns perfectly with the organization's goals.
              From user-friendly interfaces to seamless content workflows, our
              Custom CMS Solutions are crafted to enhance efficiency and deliver
              an unparalleled user experience.
            </p>
            <div className="  lg:mx-auto lg:grid lg:w-full  lg:grid-cols-1 ">
              <div className="lg:pr-4">
                <div className="max-w-screen-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                  <ul className="mt-2 space-y-4 text-gray-700 text-justify">
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          Intuitive Content Editing :
                        </strong>{" "}
                        Simplify content management with an easy-to-use
                        interface for effortless updates.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          Scalability :
                        </strong>{" "}
                        Grow your digital presence without limitations, as our
                        CMS development services adapt to your evolving needs.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          Responsive Design :
                        </strong>{" "}
                        Ensure a consistent and engaging user experience across
                        various devices.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full h-full">
            <img src="./CUSTOM CMS.png" className="w-full h-full" alt="" />
          </div>
        </div>
        <div class="grid flex-col-reverse items-center mb-24 grid-cols-1 md:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
          <div class="order-none md:order-2">
            <h2 class="mb-4 text-2xl font-bold tracking-tight text-center text-emerald-600 md:leading-tight sm:text-left md:text-4xl">
              Open-Source CMS Solutions :
            </h2>
            <p class="mb-5 text-base  text-gray-600  md:text-base text-justify">
              Harness the power of community-driven development with our
              Open-Source CMS Solutions. We leverage widely adopted platforms to
              create dynamic and cost-effective content management systems that
              foster collaboration and innovation.
            </p>
            <div className="  lg:mx-auto lg:grid lg:w-full  lg:grid-cols-1 ">
              <div className="lg:pr-4">
                <div className="max-w-screen-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                  <ul className="mt-2 space-y-4 text-gray-700 text-justify">
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          Cost-Effectiveness :
                        </strong>{" "}
                        Eliminate licensing fees associated with proprietary CMS
                        solutions, making it a budget-friendly choice.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          Extensive Plugin Ecosystem :
                        </strong>{" "}
                        Access a vast array of plugins and extensions to enhance
                        functionality and meet specific requirements.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          Community Support :
                        </strong>{" "}
                        Benefit from the collective expertise of a global
                        community, ensuring continuous improvement and updates.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full h-full">
            <img
              src="/Open-Source CMS Solutions.png"
              className="w-full h-full"
              alt=""
            />
          </div>
        </div>
        <div class="grid items-center grid-cols-1 mb-24 md:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
          <div>
            <h2 class="mb-4 text-2xl font-bold tracking-tight text-center text-emerald-600 md:leading-tight sm:text-left md:text-4xl">
              Enterprise CMS Solutions :
            </h2>
            <p class="mb-4 text-base  text-gray-600  md:text-base text-justify">
              For businesses with complex requirements and large-scale
              operations, our Enterprise CMS Solutions provide a robust
              foundation for managing extensive content ecosystems. We focus on
              scalability, security, and advanced features to meet the demands
              of enterprise-level digital initiatives.
            </p>
            <div className="  lg:mx-auto lg:grid lg:w-full  lg:grid-cols-1 ">
              <div className="lg:pr-4">
                <div className="max-w-screen-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                  <ul className="mt-2 space-y-4 text-gray-700 text-justify">
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          Enterprise-Grade Security :
                        </strong>{" "}
                        Safeguard your sensitive data with robust security
                        features and compliance standards.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          Workflow Automation :
                        </strong>{" "}
                        Streamline content creation, approval, and publishing
                        processes for optimal efficiency.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          Integration Capabilities :
                        </strong>{" "}
                        Seamlessly connect your CMS with other enterprise
                        systems to create a unified digital environment.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full h-full">
            <img
              src="/Enterprise CMS Solutions.png"
              className="w-full h-full"
              alt=""
            />
          </div>
        </div>
        <div class="grid flex-col-reverse items-center mb-24 grid-cols-1 md:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24">
          <div class="order-none md:order-2">
            <h2 class="mb-4 text-2xl font-bold tracking-tight text-center text-emerald-600 md:leading-tight sm:text-left md:text-4xl">
              Secure CMS Solutions :
            </h2>
            <p class="mb-5 text-base  text-gray-600  md:text-base text-justify">
              Security is at the core of our CMS development philosophy. Our
              Secure CMS Solutions prioritize data protection, user
              authentication, and vulnerability mitigation to ensure a resilient
              digital infrastructure.
            </p>
            <div className="  lg:mx-auto lg:grid lg:w-full  lg:grid-cols-1 ">
              <div className="lg:pr-4">
                <div className="max-w-screen-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                  <ul className="mt-2 space-y-4 text-gray-700 text-justify">
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          SSL Encryption :
                        </strong>{" "}
                        Safeguard data transmission between users and the CMS
                        with industry-standard encryption protocols.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          Regular Security Audits :
                        </strong>{" "}
                        Proactively identify and address potential
                        vulnerabilities through routine security audits.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <span>
                        <strong className="font-bold text-lg text-gray-900">
                          User Access Controls :
                        </strong>{" "}
                        Implement granular access controls to manage user
                        permissions and data confidentiality.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full h-full">
            <img
              src="/Enterprise CMS Solutions-1.png"
              className="w-full h-full"
              alt=""
            />
          </div>
        </div>
      </section>

      <section class="text-gray-600 body-font">
        <div class="container mx-auto max-w-screen-xl flex px-5 lg:px-8 py-24 md:flex-row flex-col items-center">
          <div class="max-w-screen-xl ">
            <img
              class="object-cover object-center rounded"
              alt="hero"
              src="./Banner 2.jpg"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContentMs;
