import React, { useEffect, useRef } from "react";
import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.min.css";
import "@glidejs/glide/dist/css/glide.theme.min.css";

function HomePageBanner() {
  const glideRef = useRef(null);

  useEffect(() => {
    const glide = new Glide(glideRef.current, {
      type: "carousel",
      focusAt: "center",
      perView: 1,
      autoplay: 3500,
      animationDuration: 700,
      gap: 24,
      classes: {
        activeNav: "[&>*]:bg-slate-700",
      },
      breakpoints: {
        1024: {
          perView: 2,
        },
        640: {
          perView: 1,
        },
      },
    });

    glide.mount();

    return () => {
      glide.destroy();
    };
  }, []);

  return (
    <div>
      <div class="relative w-full glide-02" ref={glideRef}>
        <div class="overflow-hidden" data-glide-el="track">
          <ul class="relative w-full overflow-hidden p-0 whitespace-no-wrap flex flex-no-wrap [backface-visibility: hidden] [transform-style: preserve-3d] [touch-action: pan-Y] [will-change: transform]">
            <li>
              <img
                src="/Sale.jpg"
                class="w-full max-w-full max-h-full m-auto"
                alt=""
              />
            </li>
            <li>
              <img
                src="/serv.jpg"
                class="w-full max-w-full max-h-full m-auto"
                alt=""
              />
            </li>
          </ul>
        </div>

        <div
          className="absolute bottom-0 flex  items-center justify-center w-full gap-2"
          data-glide-el="controls[nav]"
        >
          {[0, 1].map((index) => (
            <button
              key={index}
              className="p-4 group"
              data-glide-dir={`=${index}`}
              aria-label={`goto slide ${index + 1}`}
            >
              <span className="block w-2 h-2 transition-colors duration-300 rounded-full ring-1 ring-slate-700 bg-white/20 focus:outline-none"></span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HomePageBanner;
