import React from "react";

function Portfolio() {
  return (
    <div className="bg-lime-50">
      <div class="container  max-w-screen-xl m-auto md:px-6 flex flex-wrap flex-col md:flex-row items-center justify-start py-12">
        <div className="w-full text-xl font-medium text-justify px-4 mb-6">
          <h3 className="text-3xl lg:text-4xl  leading-7 md:leading-5 text-left text-gray-900  hover:text-lime-900">
            Our Portfolio :
          </h3>
        </div>
        <a
          href="https://tasync-landing-page.vercel.app/"
          class="w-full lg:w-1/2 p-3  card card-animation-left"
        >
          <div class="flex flex-col lg:flex-row rounded overflow-hidden h-auto lg:h-44 border shadow-blue-200 shadow-lg">
            <img
              class="block h-auto w-full lg:w-60 flex-none bg-cover h-24"
              src="/Our Product 02.jpg"
            />
            <div class="bg-gray-50 rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal shadow-default cursor-pointer">
              <div class="text-black  text-base font-medium text-justify mb-2 leading-tight">
                Tasync : Your ultimate office task manager, boosting workplace
                efficiency through seamless organization and enhanced
                productivity tools. Get things done effortlessly.
              </div>
            </div>
          </div>
        </a>

        <a
          href="https://advept.aarhatiserv.com/ "
          class="w-full lg:w-1/2 p-3 card card-animation-right"
        >
          <div class="flex flex-col lg:flex-row-reverse rounded overflow-hidden h-auto lg:h-44 border shadow-blue-200 shadow-lg">
            <img
              class="block h-auto w-full lg:w-60 flex-none bg-cover"
              src="https://images.prismic.io/aarhat/0d5fc024-36c4-4a58-a3b7-82d2064dabb8_Our+Product+03.jpg?auto=compress,format"
            />
            <div class="bg-gray-50 rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal shadow-default cursor-pointer">
              <div class="text-black  text-base font-medium text-justify mb-2 leading-tight">
                Advept : A full-service digital marketing agency that helps
                businesses grow their brands and reach new customers. We
                specialize in developing and executing customized strategies
                that align with your business goals.
              </div>
            </div>
          </div>
        </a>

        <div class="w-full lg:w-1/2 p-3 card card-animation-left">
          <div class="flex flex-col lg:flex-row-reverse rounded overflow-hidden h-auto lg:h-44 border shadow-blue-200 shadow-lg">
            <img
              class="block h-auto w-full lg:w-60 flex-none bg-cover h-24"
              src="https://images.prismic.io/aarhat/12f50b3f-943b-4f4b-a45b-de4394fe8a9b_Our+Product+04.jpg?auto=compress,format"
            />
            <div class="bg-gray-50 rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal shadow-default cursor-pointer">
              <div class="text-black  text-base font-medium text-justify mb-2 leading-tight">
                Talk Soc: Welcome to Talk Soc, your ultimate blog destination
                for a diverse spectrum of content encompassing entertainment,
                knowledge, humor, GIFs, science, and more. Explore, engage, and
                indulge your every interest.
              </div>
            </div>
          </div>
        </div>

        <div class="w-full lg:w-1/2 p-3 card card-animation-right">
          <div class="flex flex-col lg:flex-row rounded overflow-hidden h-auto lg:h-44 border shadow-blue-200 shadow-lg">
            <img
              class="block h-auto w-full lg:w-60 flex-none bg-cover h-24"
              src="/Coming Soon.jpg"
              alt=""
            />
            <div class="bg-gray-50 rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal shadow-default cursor-pointer">
              <div class="text-black  text-base font-medium text-justify mb-2 leading-tight">
                MEDIC EMR : Transforming healthcare with an advanced electronic
                medical record system, seamlessly uniting patient and doctor
                management for enhanced care efficiency.
              </div>
            </div>
          </div>
        </div>

        <div class="w-full lg:w-1/2 p-3 card card-animation-left">
          <div class="flex flex-col lg:flex-row rounded overflow-hidden h-auto lg:h-44 border shadow-blue-200 shadow-lg">
            <img
              class="block h-auto w-full lg:w-60 flex-none bg-cover h-24"
              src="/Coming Soon.jpg"
            />
            <div class="bg-gray-50 rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal shadow-default cursor-pointer">
              <div class="text-black  text-base font-medium text-justify mb-2 leading-tight">
                BuzzyBusiness : Incubating college student entrepreneurs,
                fostering innovation, mentoring, and connecting visionaries for
                successful startup journeys with resources and funding.
              </div>
            </div>
          </div>
        </div>

        <div class="w-full lg:w-1/2 p-3 card card-animation-right">
          <div class="flex flex-col lg:flex-row rounded overflow-hidden h-auto lg:h-44 border shadow-blue-200 shadow-lg">
            <img
              class="block h-auto w-full lg:w-60 flex-none bg-cover h-24"
              src="/Coming Soon.jpg"
            />
            <div class="bg-gray-50 rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal shadow-default cursor-pointer">
              <div class="text-black  text-base font-medium text-justify mb-2 leading-tight">
                ISEAS : Simplifying education financing and visa guidance,
                empowering students to achieve their academic dreams with
                tailored loans and expert support.
              </div>
            </div>
          </div>
        </div>

        <div class="w-full lg:w-1/2 p-3 card card-animation-left">
          <div class="flex flex-col lg:flex-row-reverse rounded overflow-hidden h-auto lg:h-44 border shadow-blue-200 shadow-lg">
            <img
              class="block h-auto w-full lg:w-60 flex-none bg-cover h-24"
              src="/Coming Soon.jpg"
            />
            <div class="bg-gray-50 rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal shadow-default cursor-pointer">
              <div class="text-black  text-base font-medium text-justify mb-2 leading-tight">
                Brandxo : Your one-stop solution for branding needs. From
                business cards to product branding, we bring your vision to
                life.
              </div>
            </div>
          </div>
        </div>

        <div class="w-full lg:w-1/2 p-3 card card-animation-right">
          <div class="flex flex-col lg:flex-row-reverse rounded overflow-hidden h-auto lg:h-44 border shadow-blue-200 shadow-lg">
            <img
              class="block h-auto w-full lg:w-60 flex-none bg-cover h-24"
              src="/Coming Soon.jpg"
            />
            <div class="bg-gray-50 rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal shadow-default cursor-pointer">
              <div class="text-black  text-base font-medium text-justify mb-2 leading-tight">
                Skillseed : A dynamic, creative LMS for makeup, photography, and
                fashion. Expert-led courses, interactive tutorials, and a
                vibrant community empower your creative journey.
              </div>
            </div>
          </div>
        </div>

        <div class="w-full lg:w-1/2 p-3 card card-animation-left">
          <div class="flex flex-col lg:flex-row-reverse rounded overflow-hidden h-auto lg:h-44 border shadow-blue-200 shadow-lg">
            <img
              class="block h-auto w-full lg:w-60 flex-none bg-cover h-24"
              src="/Coming Soon.jpg"
            />
            <div class="bg-gray-50 rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal shadow-default cursor-pointer">
              <div class="text-black  text-base font-medium text-justify mb-2 leading-tight">
                Funda : The groundbreaking crowdfunding platform empowering
                investors to support innovative projects, startups, and causes
                while potentially earning returns. Join us!
              </div>
            </div>
          </div>
        </div>

        <div class="w-full lg:w-1/2 p-3 card card-animation-right">
          <div class="flex flex-col lg:flex-row-reverse rounded overflow-hidden h-auto lg:h-44 border shadow-blue-200 shadow-lg">
            <img
              class="block h-auto w-full lg:w-60 flex-none bg-cover h-24"
              src="/Coming Soon.jpg"
            />
            <div class="bg-gray-50 rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal shadow-default cursor-pointer">
              <div class="text-black  text-base font-medium text-justify mb-2 leading-tight">
                Memeish : Welcome to Memeish, where trolls trip, memes laugh,
                and videos ROFL!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
