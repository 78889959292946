import Hero from "./components/Hero/Hero";
import { Routes, Route } from "react-router-dom";
import Main from "./components/Main/Main";
import Blog from "./components/Blog/Blog";
import About from "./components/About/About";
import Service from "./components/Service/Service";
import Community from "./components/Community/Community";
import Portfolio from "./components/Portfolio/Portfolio";
import Footer from "./components/Footer/Footer";
import ReadBlog from "./pages/ReadBlog";
import Privacy from "./components/Privacy/Privacy";
import Career from "./components/Career/Career";
import Contact from "./components/Contact/Contact";
import ScrollToTop from "./ScrollToTop";
import WebDev from "./components/ServiceDetail/WebDev";
import Erp from "./components/ServiceDetail/Erp";
import UiUx from "./components/ServiceDetail/UiUx";
import ContentMs from "./components/ServiceDetail/ContentMs";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Main />
      <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/community" element={<Community />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog/:slug" element={<ReadBlog />} />
        <Route path="/webdev" element={<WebDev />} />
        <Route path="/erp" element={<Erp />} />
        <Route path="/uiux" element={<UiUx />} />
        <Route path="/cms" element={<ContentMs />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
