import React from "react";

function clients() {
  return (
    <div>
      <section class="px-4 py-24 mx-auto max-w-screen-2xl">
        <h1 class="mb-12 text-lg sm:text-3xl font-bold tracking-wide text-center bg-gradient-to-b from-green-400 to-gray-400 bg-clip-text  text-transparent uppercase">
          We’re proud to have played a part in these amazing journeys.
        </h1>
        <div class="grid grid-cols-4  h-full w-full text-center lg:grid-cols-10 marquee-container">
          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-1.png"
              alt="Todoist Logo"
              class="block object-contain  "
            />
          </div>
          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-2.png"
              alt="Slack Logo"
              class="block object-contain  "
            />
          </div>
          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-3.png"
              alt="Typeform Logo"
              class="block object-contain  "
            />
          </div>
          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-4.png"
              alt="Algolia Logo"
              class="block object-contain  "
            />
          </div>
          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-5.png"
              alt="Postcss Logo"
              class="block object-contain  "
            />
          </div>
          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-6.png"
              alt="TailwindCSS Logo"
              class="block object-contain  "
            />
          </div>
          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-7.png"
              alt="Discord Logo"
              class="block object-contain  "
            />
          </div>
          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-8.png"
              alt="Vimeo Logo"
              class="block object-contain  "
            />
          </div>
          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-9.png"
              alt="Vimeo Logo"
              class="block object-contain  "
            />
          </div>

          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-10.png"
              alt="Vimeo Logo"
              class="block object-contain  "
            />
          </div>
          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-11.png"
              alt="Vimeo Logo"
              class="block object-contain  "
            />
          </div>
          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-12.png"
              alt="Vimeo Logo"
              class="block object-contain  "
            />
          </div>
          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-13.png"
              alt="Vimeo Logo"
              class="block object-contain  "
            />
          </div>
          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-14.png"
              alt="Vimeo Logo"
              class="block object-contain  "
            />
          </div>
          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-15.png"
              alt="Vimeo Logo"
              class="block object-contain  "
            />
          </div>
          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-16.png"
              alt="Vimeo Logo"
              class="block object-contain  "
            />
          </div>
          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-17.png"
              alt="Vimeo Logo"
              class="block object-contain  "
            />
          </div>
          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-18.png"
              alt="Vimeo Logo"
              class="block object-contain  "
            />
          </div>
          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-19.png"
              alt="Vimeo Logo"
              class="block object-contain  "
            />
          </div>
          <div class="flex items-center justify-center">
            <img
              src="/Our-ClientsArtboard-20.png"
              alt="Vimeo Logo"
              class="block object-contain  "
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default clients;
