import React, { useState } from "react";
import Lottie from "lottie-react";
import animation from "./animation.json";
import { Link } from "react-router-dom";

function Community() {
  return (
    <div class="bg-lime-50">
      <body>
        <div class=" flex flex-col py-12 justify-center items-center">
          <Lottie animationData={animation} className="h-[500px] bg-none" />
        </div>
        <div class="flex-shrink text-center justify-center  -mt-16 items-center max-w-full px-4 w-full">
          <Link
            to="/contact"
            class="font-semibold tracking-wide bg-lime-300 py-3 px-16 rounded-lg mb-8 shadow-lg text-gray-900 transition-colors duration-200 hover:text-deep-purple-accent-400 "
            type="submit"
          >
            Get In Touch
          </Link>
        </div>
      </body>
    </div>
  );
}

export default Community;
