import React from "react";
import { Link } from "react-router-dom";

function About() {
  return (
    <div>
      <div className="bg-white py-16 sm:py-8 lg:py-28">
        <div className="mx-auto max-w-screen-xl px-4 md:px-8">
          <div className="grid gap-8 md:grid-cols-2 lg:gap-12">
            <div>
              <div className="h-full overflow-hidden   md:h-full">
                <img
                  src="/Home 2.png"
                  loading="lazy"
                  alt=""
                  className="h-full w-full object-cover object-center"
                />
              </div>
            </div>

            <div className="md:pt-20">
              <p className=" font-bold text-lime-700 md:text-left">
                Who we are
              </p>

              <h1 className="mb-4  text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6 md:text-left">
                Our Competitive Advantage
              </h1>

              <p className="mb-6 text-gray-500 sm:text-lg md:mb-6 text-justify">
                At Aarhatiserv, we understand that the digital landscape is
                constantly evolving, and staying ahead of the curve is crucial.
                That's why our team is comprised of experienced professionals
                who are not only experts in their respective fields but are also
                dedicated to continuous learning and improvement. We believe in
                harnessing the power of technology to solve complex problems and
                create seamless user experiences.
              </p>
              <br />
              <Link
                to="/about"
                className="mb-2 text-center hover:bg-indigo-200 bg-lime-200 shadow-lg py-3 px-6 rounded shadow-default text-base font-semibold text-gray-800 sm:text-xl md:mb-4 md:text-left"
              >
                About us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
