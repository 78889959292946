import React from "react";

function WebDev() {
  return (
    <div>
      <section class="text-gray-600 body-font">
        <div class="container mx-auto max-w-screen-xl flex px-5 lg:px-8 py-24 md:flex-row flex-col items-center">
          <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="title-font sm:text-4xl text-3xl mb-6 leading-relaxed py-1 font-medium text-gray-900">
              Top Rated Website Design And
              <br class="hidden lg:inline-block" />
              Development Company
            </h1>
            <p class="leading-relaxed text-justify">
              Custom website designs and web development, together with
              excellent brand engagement, provide the best possible conversion
              rate for your business.
              <br />
              <br />
              We offer website design and web development services that are
              fully equipped with the latest technological tools and are also
              familiar with the most successful search engine optimisation
              techniques. Our website design and development services take into
              account the variables mentioned. We make sure the website has a
              unique existence in the cloud.
            </p>
          </div>
          <div class="lg:max-w-xl lg:w-full md:w-1/2 w-full">
            <img
              class="object-cover object-center rounded"
              alt="hero"
              src="/web development.png"
            />
          </div>
        </div>
      </section>
      {/* our services section start  */}
      <section className="max-w-screen-xl container mx-auto">
        <h1 className="text-center text-4xl font-bold py-6">Our Services</h1>
        <div class="bg-white">
          <section class="mx-auto w-full gap-y-4   px-4 py-10 sm:px-10 flex flex-col">
            <h2 class="w-full text-base font-bold text-gray-700 sm:text-xl text-justify ">
              Website Designing & Development :{" "}
              <span className="text-gray-600 font-normal text-lg text-justify">
                Our team crafts visually captivating designs that seamlessly
                merge aesthetics with functionality. We believe in creating
                digital landscapes that not only attract attention but also
                engage and convert visitors into loyal customers
              </span>
            </h2>
          </section>
        </div>

        <div class="bg-lime-50">
          <section class="mx-auto w-full gap-y-4   px-4 py-10 sm:px-10 flex flex-col">
            <h2 class="w-full text-base font-bold text-gray-700 sm:text-xl text-justify ">
              CMS Design & Implementation :{" "}
              <span className="text-gray-600 font-normal text-lg text-justify">
                Empower your online platform with the agility and flexibility of
                a robust Content Management System (CMS). We specialize in
                implementing intuitive CMS solutions that enable easy content
                management, ensuring your website remains dynamic and
                up-to-date.
              </span>
            </h2>
          </section>
        </div>

        <div class="bg-white">
          <section class="mx-auto w-full gap-y-4   px-4 py-10 sm:px-10 flex flex-col">
            <h2 class="w-full text-base font-bold text-gray-700 sm:text-xl text-justify ">
              Ecommerce Website Development :{" "}
              <span className="text-gray-600 font-normal text-lg text-justify">
                Amplify your online business potential with our bespoke
                eCommerce solutions. From user-friendly interfaces to secure
                payment gateways, we tailor eCommerce platforms that elevate the
                shopping experience for your customers.
              </span>
            </h2>
          </section>
        </div>

        <div class="bg-lime-50">
          <section class="mx-auto w-full gap-y-4   px-4 py-10 sm:px-10 flex flex-col">
            <h2 class="w-full text-base font-bold text-gray-700 sm:text-xl text-justify ">
              Website Maintenance :
              <span className="text-gray-600 font-normal text-lg text-justify">
                Continuous maintenance is key to a thriving online presence. Our
                dedicated team ensures that your website runs smoothly,
                implementing updates, fixing issues, and providing ongoing
                support so you can focus on your core business.
              </span>
            </h2>
          </section>
        </div>
      </section>

      <section class="text-gray-600 body-font">
        <div class="container mx-auto max-w-screen-xl flex px-5 lg:px-8 py-24 md:flex-row flex-col items-center">
          <div class=" w-full">
            <img
              class="object-cover object-center rounded"
              alt="hero"
              src="/App development.png"
            />
          </div>
        </div>
      </section>
      {/* why choose us section  */}
      <section class="text-gray-600 body-font">
        <div class="max-w-screen-xl container px-5 lg:px-8 py-24 mx-auto flex flex-wrap">
          <div class="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
            <img
              alt="feature"
              class="object-cover object-center h-full w-full"
              src="/web development.png"
            />
          </div>
          <div class="flex flex-col flex-wrap lg:py-16 lg:w-1/2 lg:pl-12 lg:text-left text-center">
            <div class=" inline-flex items-center justify-center lg:justify-start rounded-full mb-5">
              <h2 class="text-gray-900 text-4xl title-font font-bold mb-3">
                WHY CHOOSE US ?
              </h2>
            </div>
            <div class="flex flex-col mb-6 lg:items-start items-center">
              <div class="flex-grow">
                <h2 class="text-gray-900 text-xl title-font font-bold mb-2">
                  Customer-Centric Approach :
                </h2>
                <p class="leading-relaxed text-base text-justify">
                  At Aarhatiserv, the best website designing and web development
                  company in Kolkata relies on understanding your vision. We
                  listen intently to your needs, aspirations, and objectives. By
                  meticulously understanding your requirements, we craft
                  tailored solutions that not only meet but exceed your
                  expectations.
                </p>
              </div>
            </div>
            <div class="flex flex-col mb-6 lg:items-start items-center">
              <div class="flex-grow">
                <h2 class="text-gray-900 text-xl title-font font-bold mb-2">
                  Mastery of Web Development :
                </h2>
                <p class="leading-relaxed text-base text-justify">
                  Behind every successful web design and development is a blend
                  of innovation and technical finesse. With a team well-versed
                  in the intricacies of web development, we navigate through the
                  dynamic digital landscape effortlessly. Whether it's frontend
                  design, backend functionality, or the latest trends, we know
                  the terrain inside and out.
                </p>
              </div>
            </div>
            <div class="flex flex-col mb-6 lg:items-start items-center">
              <div class="flex-grow">
                <h2 class="text-gray-900 text-xl title-font font-bold mb-2">
                  12 Years of Unrivalled Expertise :
                </h2>
                <p class="leading-relaxed text-base text-justify">
                  Experience is our backbone. For over a decade, we've honed our
                  skills, evolved with industry shifts, and delivered excellence
                  consistently. Our extensive portfolio speaks volumes about our
                  commitment to quality and our ability to adapt to the
                  ever-evolving digital realm.
                </p>
              </div>
            </div>
            <div class="flex flex-col mb-6 lg:items-start items-center">
              <div class="flex-grow">
                <h2 class="text-gray-900 text-xl title-font font-bold mb-2">
                  Post-Deployment Support :
                </h2>
                <p class="leading-relaxed text-base text-justify">
                  Our dedication doesn't cease with the completion of your
                  project. We believe in forging lasting relationships. Our
                  after-sale support ensures that your website operates
                  seamlessly. Any troubleshooting or updates needed? You can
                  count on our team to stand by your side.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="text-gray-600 body-font">
        <div class="container mx-auto max-w-screen-xl flex px-5 lg:px-8 py-24 md:flex-row flex-col items-center">
          <div class="max-w-screen-xl ">
            <img
              class="object-cover object-center rounded"
              alt="hero"
              src="./Banner 5.jpg"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default WebDev;
