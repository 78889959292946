import React from "react";
import Tech from "./Tech";
import Why from "./Why";
import About from "./About";
import Testimonial from "./Testimonial";
import Clients from "./Clients";
import { Link } from "react-router-dom";
import Blog from "./Blog";
import Banner from "./Banner";

function Hero() {
  return (
    <div>
      <section class="bg-gradient-to-r from-lime-100  to-lime-300">
        <div class="grid max-w-screen-xl px-7 py-16 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-20 lg:grid-cols-12 ">
          <div class="mr-auto place-self-center lg:col-span-7 card-animation-left">
            <h1 class="max-w-2xl mb-5 text-4xl text-black font-bold   tracking-wide md:text-5xl xl:text-5xl ">
              Building digital products &amp; brands.
            </h1>

            <p class="max-w-2xl mb-6  px-1 font-normal text-stone-700 lg:mb-8 md:text-lg lg:text-lg text-justify ">
              In the era of cloud computing, SaaS is at the forefront of
              business efficiency. We specialize in developing cutting-edge SaaS
              applications that help streamline your operations, reduce costs,
              and enhance productivity. Whether you need a custom CRM, project
              management tool, or any other SaaS solution, our team is ready to
              turn your vision into reality.
            </p>

            <div class="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <Link
                to="/service"
                href="!#"
                class="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center border border-gray-400 text-gray-900  bg-lime-100  rounded-lg sm:w-auto hover:bg-gray-100  focus:ring-lime-100 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-4 h-4 mr-2 "
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.72 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L11.69 12 4.72 5.03a.75.75 0 010-1.06zm6 0a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06L17.69 12l-6.97-6.97a.75.75 0 010-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>
                Go to Service Page
              </Link>
            </div>
          </div>

          <div class=" lg:mt-0 lg:col-span-5 lg:flex card-animation-right">
            <img src="/Home 1.png" alt="" />
          </div>
        </div>
      </section>
      <div>
        <About />

        <Tech />
        <Why />
        <Banner />
        <Clients />
        <Testimonial />
        <Blog />
      </div>
    </div>
  );
}

export default Hero;
