import React from "react";

function Why() {
  return (
    <div className="">
      <div class=" py-16 sm:py-8 lg:py-28">
        <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
          <div class="mb-10 md:mb-16">
            <h2 class="mb-4 text-center text-3xl font-bold text-gray-800 md:mb-6 lg:text-3xl">
              Why Choose Us ?
            </h2>

            <p class="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">
              This is a section of some simple filler text, also known as
              placeholder text. It shares some characteristics of a real written
              text but is random or otherwise generated.
            </p>
          </div>

          <div class="grid gap-4 sm:grid-cols-2 md:gap-8 xl:grid-cols-3">
            <div class="flex divide-x rounded-lg border  shadow-lg hover:shadow-orange-300 shadow-blue-200 hover:shadow-md card">
              <div class="flex items-center p-2 text-indigo-500 md:p-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 md:h-8 md:w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>

              <div class="p-4 md:p-6">
                <h3 class="mb-2 text-lg font-semibold md:text-xl">
                  Innovation :
                </h3>
                <p class="text-gray-500">
                  We embrace the latest technologies and best practices to
                  ensure that our solutions are always at the forefront of
                  industry trends.
                </p>
              </div>
            </div>

            <div class="flex divide-x rounded-lg border shadow-lg hover:shadow-orange-300 shadow-blue-200 hover:shadow-md card">
              <div class="flex items-center p-2 text-indigo-500 md:p-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 md:h-8 md:w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>

              <div class="p-4 md:p-6">
                <h3 class="mb-2 text-lg font-semibold md:text-xl">
                  Expertise :{" "}
                </h3>
                <p class="text-gray-500">
                  Our team comprises professionals with years of experience in
                  their respective domains. We bring a knowledge and expertise
                  to every project.
                </p>
              </div>
            </div>

            <div class="flex divide-x rounded-lg border shadow-lg hover:shadow-orange-300 shadow-violet-200 hover:shadow-md card">
              <div class="flex items-center p-2 text-indigo-500 md:p-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 md:h-8 md:w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>

              <div class="p-4 md:p-6">
                <h3 class="mb-2 text-lg font-semibold md:text-xl">Security</h3>
                <p class="text-gray-500">
                  We employ industry-standard security measures to protect your
                  information from unauthorized access, disclosure, alteration,
                  or destruction.
                </p>
              </div>
            </div>

            <div class="flex divide-x rounded-lg border shadow-lg hover:shadow-orange-300 shadow-green-200 hover:shadow-md card">
              <div class="flex items-center p-2 text-indigo-500 md:p-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 md:h-8 md:w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>

              <div class="p-4 md:p-6">
                <h3 class="mb-2 text-lg font-semibold md:text-xl">
                  Quality Assurance :{" "}
                </h3>
                <p class="text-gray-500">
                  We have a rigorous quality assurance process in place to
                  ensure that our deliverables are of the highest standard.
                </p>
              </div>
            </div>

            <div class="flex divide-x rounded-lg border shadow-lg hover:shadow-orange-300 shadow-blue-200 hover:shadow-md card">
              <div class="flex items-center p-2 text-indigo-500 md:p-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 md:h-8 md:w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>

              <div class="p-4 md:p-6">
                <h3 class="mb-2 text-lg font-semibold md:text-xl">
                  Transparency :
                </h3>
                <p class="text-gray-500">
                  We believe in transparent communication and project
                  management. You'll always be in the loop, from project kickoff
                  to delivery.
                </p>
              </div>
            </div>

            <div class="flex divide-x rounded-lg border shadow-lg hover:shadow-orange-300 shadow-violet-200 hover:shadow-md card">
              <div class="flex items-center p-2 text-indigo-500 md:p-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 md:h-8 md:w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>

              <div class="p-4 md:p-6">
                <h3 class="mb-2 text-lg font-semibold md:text-xl">
                  Client-Centric :
                </h3>
                <p class="text-gray-500">
                  Your satisfaction is our top priority. We are committed to
                  delivering solutions that not only meet but exceed your
                  expectations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Why;
