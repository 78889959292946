import React from "react";
import Banner from "./Banner";
import { Link } from "react-router-dom";
import { usePrismicDocumentsByType, PrismicRichText } from "@prismicio/react";

function Blog() {
  const [blogs] = usePrismicDocumentsByType("blog");
  return (
    <div className="bg-lime-50">
      {/* <Banner /> */}
      <div class="max-w-screen-xl mx-auto px-1 md:px-8">
        <main>
          <div class="block md:flex md:space-x-2 px-2 py-4 md:py-12 ">
            <div class="block lg:flex lg:space-x-2 px-2 lg:p-0 mt-10 mb-10">
              <div className="grid grid-cols-1 lg:grid-cols-1">
                {blogs?.results?.map((blog, index) => (
                  <div class="w-full lg:w-5/6">
                    <Link
                      to={`/blog/${blog.uid}`}
                      class="block rounded w-full lg:flex mb-10"
                    >
                      <img
                        src={blog?.data.banner.url}
                        alt=""
                        class="h-50 lg:w-48 object-cover object-center  flex-none bg-cover text-center overflow-hidden opacity-75"
                        title="deit is very important"
                      />
                      <div class="bg-white rounded px-4 flex flex-col justify-between leading-normal">
                        <div>
                          <div class="mt-3 md:mt-3 text-gray-700 font-bold text-2xl mb-2">
                            <PrismicRichText field={blog.data.heading} />
                          </div>
                          <p class="text-gray-700 py-1 text-base line-clamp-3">
                            <PrismicRichText field={blog.data.description} />
                          </p>
                        </div>
                        <div class="flex mt-3 mb-4">
                          <img
                            src="/aarhat_logo.png"
                            class="h-10 w-10 rounded-full mr-2 object-cover"
                            alt=""
                          />
                          <div>
                            <p class="font-semibold text-gray-700 text-sm capitalize">
                              {" "}
                              Aarhat Iserv{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>

              <div class="w-full lg:w-5/6 px-3">
                <div class="mb-4">
                  <h5 class="font-bold text-lg uppercase text-gray-700 px-1 mb-2">
                    {" "}
                    Category{" "}
                  </h5>
                  <ul>
                    <li class="px-1 py-4 border-b border-t border-white hover:border-gray-200 transition duration-300">
                      <a
                        href="!#"
                        class="flex items-center text-gray-600 cursor-pointer"
                      >
                        <span class="inline-block h-4 w-4 bg-green-300 mr-3"></span>
                        Technology
                        <span class="text-gray-500 ml-auto">2 articles</span>
                        <i class="text-gray-500 bx bx-right-arrow-alt ml-1"></i>
                      </a>
                    </li>
                    <li class="px-1 py-4 border-b border-t border-white hover:border-gray-200 transition duration-300">
                      <a
                        href="!#"
                        class="flex items-center text-gray-600 cursor-pointer"
                      >
                        <span class="inline-block h-4 w-4 bg-indigo-300 mr-3"></span>
                        General knowledge
                        <span class="text-gray-500 ml-auto">1 articles</span>
                        <i class="text-gray-500 bx bx-right-arrow-alt ml-1"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Blog;
