import { PrismicRichText, usePrismicDocumentByUID } from "@prismicio/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

function ReadBlog() {
  const params = useParams();
  const { slug } = params;

  const [blog] = usePrismicDocumentByUID("blog", slug);
  console.log(blog);

  return blog ? (
    <div>
      <div className="bg-gray-50">
        <div className="max-w-screen-xl py-12 px-2 md:px-10 mx-auto">
          <main class="">
            <div class="mb-4 md:mb-0 w-full mx-auto relative">
              <img
                src={blog?.data.banner.url}
                alt={blog?.data.banner.alt}
                class="w-full h-full  py-8  object-center lg:rounded"
              />
              <div class="px-4 lg:px-0">
                <h2 class="text-4xl font-bold    font-heading leading-tight">
                  <PrismicRichText field={blog.data.heading} />
                </h2>
              </div>
            </div>

            <div className="mt-6">
              <h1 className="px-4 lg:px-0 text-2xl font-medium font-heading ">
                Introduction :
              </h1>
              <div class="flex flex-col lg:flex-row lg:space-x-8">
                <div class="px-4 lg:px-0 mt-2  text-center text-lg leading-relaxed w-full lg:w-3/4">
                  <p class="pb-0 text-justify font-assistant ">
                    <PrismicRichText field={blog.data.description} />
                  </p>
                </div>

                <div class="w-full lg:w-1/4  m-auto mt-4 max-w-screen-sm">
                  <div class="p-4 border-t border-b  md:border md:rounded">
                    <div class="flex py-2">
                      <img
                        src="/aarhat_logo.png"
                        class="h-12 w-12 rounded-full mr-2 object-cover"
                        alt=""
                      />
                      <div>
                        <p class="font-semibold  text-base"> Rajsekhar N </p>
                        <p class="font-semibold  text-sm"> Writer </p>
                      </div>
                    </div>
                    <p class=" text-justify py-3">
                      We writes about technology Yourself required no at
                      thoughts delicate landlord it be. Branched dashwood do is
                      whatever it.
                    </p>
                    <button class="px-2 py-1  bg-blue-700 flex w-full items-center justify-center rounded">
                      <a href="https://www.instagram.com/aarhatiserv/">
                        {" "}
                        Follow
                      </a>

                      <i class="bx bx-user-plus ml-2"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 lg:-mt-28">
              <div class="flex flex-col lg:flex-row lg:space-x-8">
                <div class="px-4 lg:px-0 mt-2  text-center text-lg leading-relaxed w-full lg:w-2/3">
                  <p class="pb-0 text-justify font-assistant ">
                    <PrismicRichText
                      field={blog.data.desc}
                      components={{
                        paragraph: ({ children }) => (
                          <p className="mb-4">{children}</p>
                        ),
                      }}
                    />
                  </p>
                </div>
              </div>
            </div>

            <div class="mb-4 md:mb-0 w-full mx-auto relative">
              <img
                src={blog?.data.image.url}
                alt={blog?.data.image.alt}
                class="w-3/4 mt-2   object-center lg:rounded"
              />
            </div>

            <div className="mt-6">
              <h1 className="px-4 lg:px-0 text-2xl font-medium font-heading ">
                Conclusion :
              </h1>
              <div class="px-4 lg:px-0 mt-2  text-center text-lg leading-relaxed w-full lg:w-3/4">
                <p class="pb-8 text-justify font-assistant ">
                  <PrismicRichText field={blog.data.conclusion} />
                </p>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default ReadBlog;
